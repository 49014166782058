import roofconfirm from './RoofConfirm.module.css'
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import React, { useState } from "react";
import GoogleMaps from "./GoogleMaps";

const RoofConfirm = ({ nextStep, handleFormData, prevStep, values }) => {

  const [error, setError] = useState(false);

  // after form submit validating the form data using validator


  var retrievedObject = localStorage.getItem('places');
console.log('retrieved data');
console.log('retrievedObject: ', JSON.parse(retrievedObject));
let lat = JSON.parse(retrievedObject).geometry.location.lat;
let lng = JSON.parse(retrievedObject).geometry.location.lng;
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    nextStep();
  };


  return (
    <>
      <div>
        <Card style={{ border: 0 }}>
          <Card.Body>
            <Form onSubmit={submitFormData} className={roofconfirm.luminson__googlemap}>
            <Form.Group className={`mb-3 ${roofconfirm.luminson__map}`}>
                  <Form.Label><h2>Confirm Your Roof</h2><p>If the pin isn't on your correct, just move it to the right property</p></Form.Label>
              <GoogleMaps style={{width: '100%'}} isMarkerShown={true} latitude={lat} longtitude={lng} />
              <div className={`${roofconfirm.luminson__buttons}`} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "2rem" }}>
                <Button className={`${roofconfirm.luminson__continue_button}`} onClick={prevStep}>
                  Previous
                </Button>
                <Button type="submit" className={`${roofconfirm.luminson__continue_button}`}>
                  Continue
                </Button>
              </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default RoofConfirm;