import lessexpensive from './LessExpensive.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DisplayCard from './partials/DisplayCard';
import SectionHeading from './partials/SectionHeading';
import FlipCard from './partials/FlipCard';
import { useState, useEffect } from 'react';

const LessExpensive = () => {


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);


    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    const cards = [
        {
            id: "1",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Large Overhead Costs",
            front: "Large_Overhead_Costs_A",
            theme: 'dark',
            col: '4',
            looks: 'extended',
            back: <ul>
                <li>Big Office Headquarters</li>
                <li>Company Wrapped Vehicles</li>
                <li>High Salary Management</li>
                <li>Investor Shareholder Payouts</li>
            </ul>
        },
        {
            id: "2",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Door-to-Door Salespeople",
            front: "Door-to-door_Salespeoples_A1",
            theme: 'dark',
            col: '4',
            looks: 'extended',
            back: <ul>
                <li>Extra Large Commission Checks</li>
                <li>City Canvasing/Knocking Permits</li>
                <li>Uniforms, IPads, Laptops</li>
                <li>Air Fare and Lodging</li>
                <li>Deceptive Practices of Maxing out you system size for maximizing their Commissions and Bonuses</li>
            </ul>
        },
        {
            id: "3",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Hidden Soft Costs",
            front: "Hidden_Soft_Cost_B",
            theme: 'dark',
            col: '4',
            looks: 'normal',
            back: <ul>
                <li>Drafting and Engineering</li>
                <li>Permitting and City Approvals</li>
                <li>Site Surveying and Assessments</li>
                <li>Project Managers</li>
                <li>Install Labor Crews</li>
            </ul>
        },
        {
            id: "4",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Excessive Hidden Fees",
            front: "Excessive_Hidden_Financing_Fees_B",
            theme: 'dark',
            col: '4',
            looks: 'full',
            back: <ul>
                <li>Greedy Finance Companies can add up to 38% to costs for longterm low interest financing</li>
            </ul>
        },
        {
            id: "5",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Pricey Equipment Markup",
            front: "Pricey_Equipment_Markups_A",
            theme: 'dark',
            col: '4',
            looks: 'normal',
            back: <ul>
                <li>Paying extra for Solar Panels</li>
                <li>Markup on Batteries Inverters</li>
                <li>Padding costs on Racking and Install Equipment</li>
            </ul>
        },
        {
            id: "6",
            variant: (windowSize[0] > 991) ? "focus" : "click",
            title: "Long Install Times",
            front: "Long_Inatall_Times_A",
            theme: 'dark',
            col: '4',
            looks: 'normal',
            back: <ul>
                <li>Larger install companies are backlogged and can take up to 3 - 6 months to install in some cases</li>
            </ul>
        }
    ];

    return (
        <>

            <div className={`section ${lessexpensive.luminson__lessexpensive_section}`}>
                <Container>
                    <div className={lessexpensive.luminson_sectionheading}>
                        <SectionHeading heading="They Are Charging You Way Too Much." sub="Traditional solar companies are super expensive to operate." align="text-center" />
                    </div>

                    <Row md={12}>
                        {cards.map((card) => (
                            <FlipCard key={card.id} card={card} cols='4' title={card.title} icon={card.front} theme={card.theme} text={
                                <>
                                    <ul>
                                        <li>Big Office Headquarters</li>
                                        <li>Company Wrapped Vehicles</li>
                                        <li>High Salary Management</li>
                                        <li>Investor Shareholder Payouts</li>
                                    </ul>
                                </>
                            } />
                        ))}
                    </Row>

                    <Row md={12} className='d-none'>
                        <DisplayCard cols='4' title='Large Overhead Costs' icon='Large_Overhead_Costs_A_1' theme='dark' text={
                            <>
                                <ul>
                                    <li>Big Office Headquarters</li>
                                    <li>Company Wrapped Vehicles</li>
                                    <li>High Salary Management</li>
                                    <li>Investor Shareholder Payouts</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='4' title='Door-to-Door Salespeople' theme='dark' icon='Door-to-door_Salespeoples_A' text={
                            <>
                                <ul>
                                    <li>Extra Large Commission Checks</li>
                                    <li>City Canvasing/Knocking Permits</li>
                                    <li>Uniforms, IPads, Laptops</li>
                                    <li>Air Fare and Lodging</li>
                                    <li>Deceptive Practices of Maxing out you system size for maximizing their Commissions and Bonuses</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='4' title='Hidden Soft Costs' theme='dark' icon='Hidden_Soft_Cost_A' text={
                            <>
                                <ul>
                                    <li>Drafting and Engineering</li>
                                    <li>Permitting and City Approvals</li>
                                    <li>Site Surveying and Assessments</li>
                                    <li>Project Managers</li>
                                    <li>Install Labor Crews</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='4' title='Excessive Hidden Fees' theme='dark' icon='Excessive_Hidden_Financing_Fees_A' text={
                            <>
                                <ul>
                                    <li>Greedy Finance Companies can add up to 38% to costs for longterm low interest financing</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='4' title='Pricey Equipment Markup' theme='dark' icon='Pricey_Equipment_Markups_A' text={
                            <>
                                <ul>
                                    <li>Paying extra for Solar Panels</li>
                                    <li>Markup on Batteries Inverters</li>
                                    <li>Padding costs on Racking and Install Equipment</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='4' title='Long Install Times' theme='dark' icon='Long_Inatall_Times_A' text={
                            <>
                                <ul>
                                    <li>Larger install companies are backlogged and can take up to 3 - 6 months to install in some cases</li>
                                </ul>
                            </>
                        } />

                    </Row>
                    <div className={lessexpensive.luminson_bottomheading}>
                        <SectionHeading heading="You See How All of These Things Can Add Up Pretty Quickly?" sub="" align="text-center" />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default LessExpensive;