import powerbill from './PowerBill.module.css'
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import React, { useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const PowerBill = ({ nextStep, handleFormData, prevStep, values }) => {
    const [value, setValue] = React.useState(180);

    const [error, setError] = useState(false);

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');


    const radios = [
        { name: 'Active', value: '1' },
        { name: 'Radio', value: '2' },
        { name: 'Radio', value: '3' },
    ];
    const submitFormData = (e) => {
        e.preventDefault();
            nextStep();
    };


    return (
        <>
            <div>
                <Card style={{ border: 0 }}>
                    <Card.Body>
                        <Form onSubmit={submitFormData}>
                            <Form.Group className={`mb-5 ${powerbill.luminson__power_bill}`}>
                                <Form.Label><h2>Best guess of your average monthly power bill?</h2></Form.Label>
                                <RangeSlider
                                    value={value}
                                    onChange={e => setValue(e.target.value)}
                                    tooltipLabel={currentValue => `$${currentValue}`}
                                    tooltip='on'
                                    name="powerbill"
                                    min="50"
                                    max="500"
                                    step="20"
                                    orientation="horizontal"
                                    onMouseUp={handleFormData("powerbill")}
                                />
                                {error ? (
                                    <Form.Text style={{ color: "red", textAlign: "center" }}>
                                        Move the slider
                                    </Form.Text>
                                ) : (
                                    ""
                                )}
                            </Form.Group>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <Button className={`${powerbill.luminson__continue_button}`} onClick={prevStep}>
                                    Previous
                                </Button>
                                <Button type="submit" className={`${powerbill.luminson__continue_button}`}>
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default PowerBill;