import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './fonts.css';

import Navigation from './components/elements/Navigation';
import Footer from './components/elements/Footer';

import Homepage from './components/pages/Homepage';
import Terms from './components/pages/Terms';

import ScrollUp from './components/sections/partials/ScrollUp';

import ScrollToTop from "react-scroll-to-top";
import { Link, animateScroll as scroll } from "react-scroll";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Privacy from './components/pages/Privacy';
import HomeEfficientFirst from './components/pages/HomeEfficientFirst';
import { useEffect } from 'react';
import OptOut from './components/pages/OptOut';
import StartAnalysis from './components/pages/StartAnalysis';
import Blog from './components/pages/Blog';
import SingleBlogPost from './components/pages/SingleBlogPost';
import Partners from './components/pages/Partners';
import Referrals from './components/pages/Referrals';
import TestForm from './components/pages/TestForm';
import TruthAboutSolar from './components/pages/TruthAboutSolar';
import NotFoundPage from './components/pages/NotFoundPage';


function App() {

  return (
    <div className="App">
      <Router>
        <Navigation />
        <ScrollUp />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/start-analysis/" element={<StartAnalysis />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/home-efficient-first" element={<HomeEfficientFirst />} />
          <Route path="/opt-out" element={<OptOut />} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/blog/:slug/' element={<SingleBlogPost />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/test" element={<TestForm />} />
          <Route path="/thetruth" element={<TruthAboutSolar />} />
          <Route path='*' element={<NotFoundPage />}/>
        </Routes>
        <Footer />
        <ScrollToTop smooth />
      </Router>
    </div>
  );
}

export default App;
