import futureplans from './FuturePlans.module.css'

import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import React, { useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TextTransition, { presets } from "react-text-transition";
import { useEffect } from 'react';
import Image from 'react-bootstrap/Image'

const FuturePlans = ({ nextStep, handleFormData, prevStep, values }) => {

    const TEXTS = [
        "Mapping out your roof orientation",
        "Measuring your roof line",
        "Electrical data collection",
        "Processing energy needs assessment",
        "Formulating low-cost energy suggestions",
        "Drafting System plans",
        "Estimating savings and benefits report"
    ];

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            1000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    const [value, setValue] = React.useState(25);

    const [error, setError] = useState(false);
    const [animation, setAnimation] = useState(false);

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');
    const [plans, setPlans] = useState([]);
    const radios = [
        { name: 'Hot Tub', value: '1' },
        { name: 'Electric Vehicle', value: '2' },
        { name: 'Electric Smoker', value: '3' },
    ];

    const handleChange =e =>{
        const isChecked = e.target.checked;
        if(isChecked){
            setPlans([...plans, e.target.value]);
        }else{
            console.log('remove');
        }
    }
      


    const submitFormData = (e) => {

        e.preventDefault();
        // checking if value of first name and last name is empty show error else take to step 2
        if (
            validator.isEmpty(values.futureplans)
        ) {
            setError(true);
        } else {
            setAnimation(true);
            setTimeout(() => {
                nextStep();
            }, 7000)
        }
    };

    return (
        <>
            <div>
                {!animation ? (
                    <Card style={{ border: 0 }}>
                        <Card.Body>
                            <Form onSubmit={submitFormData}>
                                <Form.Group className={`mb-5 ${futureplans.luminson__future_plans}`}>
                                    <Form.Label><h2>Choose any of the following you have or plan to have in the future:</h2></Form.Label>
                                    <div className={futureplans.luminson__option_buttons_wrapper} onChange={handleFormData("futureplans")}>
                                        <label for="hottub" className={futureplans.luminson__option_button}>
                                            <Image src={('/images/hottub.svg')} width='100' className={futureplans.image} />
                                            <input type="checkbox" id="hottub" value="hottub" name="futureplans[]" defaultChecked={values.futureplans === 'hottub'} onChange={handleChange} /> Hot Tub
                                        </label>
                                        <label for="electricvehicle" className={futureplans.luminson__option_button}>
                                            <Image src={('/images/evchl.svg')} width='100' className={futureplans.image} />
                                            <input type="checkbox" id="electricvehicle" value="electricvehicle" name="futureplans[]" defaultChecked={values.futureplans === 'electricvehicle'} onChange={handleChange} /> Electric Vehicle
                                        </label>
                                        <label for="electricsmoker" className={futureplans.luminson__option_button}>
                                            <Image src={('/images/ESmoker.svg')} width='100' className={futureplans.image} />
                                            <input type="checkbox" id="electricsmoker" value="electricsmoker" name="futureplans[]" defaultChecked={values.futureplans === 'electricsmoker'} onChange={handleChange} /> Electric Smoker
                                        </label>
                                    </div>
                                    {error ? (
                                        <Form.Text style={{ color: "red", textAlign: "center" }}>
                                            Select atleast one option.
                                        </Form.Text>
                                    ) : (
                                        ""
                                    )}
                                </Form.Group>

                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Button className={`${futureplans.luminson__continue_button}`} onClick={prevStep}>
                                    Previous
                                </Button>
                                    <Button type="submit" className={`${futureplans.luminson__continue_button}`}>
                                        Continue
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                ) : (
                    <>
                        <div className="loadingAnimation">
                            <h2>
                                <TextTransition springConfig={presets.wobbly}>
                                    {TEXTS[index % TEXTS.length]}
                                </TextTransition>
                            </h2>
                            <div className="wrapper">
                                <div class="ball-loader">
                                    <div class="ball-loader-ball ball1"></div>
                                    <div class="ball-loader-ball ball2"></div>
                                    <div class="ball-loader-ball ball3"></div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default FuturePlans;