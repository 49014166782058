import hero from './Hero.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import HeroImage from "./images/hero-image.webp";
import PrimaryButton from '../elements/PrimaryButton';
import '../sections/partials/ViewPort';
import { useRef, useState, useEffect } from 'react';

const Hero = () => {

    const elementRef = useRef();

    const useIsInViewport = (ref) => {
        const [isInViewPort, setIsInViewport] = useState(false);
      
        useEffect(() => {

          function handleScroll() {    
        
            const { bottom } = ref.current.getBoundingClientRect();

            return setIsInViewport(window.innerHeight - bottom > 20);
          }
      
          window.addEventListener("scroll", handleScroll);
      
          return () => window.removeEventListener("scroll", handleScroll);
        }, [ref, isInViewPort]);
        
        return isInViewPort;
      }


      const getFadeLeftStyles = isfadeLeftInViewPort => ({
        transition: 'all 1s ease-in',
        opacity: isfadeLeftInViewPort ? '1' : '0',
        transform: isfadeLeftInViewPort ? '' : 'translateX(100%)'
    });

    const getFadeRightStyles = isfadeRightInViewPort => ({
        transition: 'all 1s ease-in',
        opacity: isfadeRightInViewPort ? '1' : '0',
        transform: isfadeRightInViewPort ? '' : 'translateX(-100%)'
    });

    const isInViewport = useIsInViewport(elementRef);


    return (
        <>
            <div className={`section ${hero.luminson__hero_section}`}>
                <Container>
                    <Row md={12} >
                        <Col md={6} className='d-flex align-items-center justify-content-center'>
                            <div getStyles={getFadeLeftStyles} className={hero.luminson__hero_caption}>
                                <h1>Thinking about Solar?</h1>
                                <h4>We take a different approach.</h4>
                                <div className={hero.infoText}>
                                    <p>Let's make sure your home is efficient first.</p>
                                    <p>Our Team of Certified Home Energy Advisors are here to help you save the most money.</p>
                                </div>
                                <PrimaryButton name='ESTIMATE MY ENERGY SAVINGS' theme='red' link='/start-analysis/' />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={hero.luminson__hero_image}>
                                <Image src={HeroImage} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Hero;