import React, { useEffect, useState, useRef } from 'react';
import truth from './TruthAboutSolar.module.css'
import { Container, Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import bulletIcon from "../sections/images/arrow.svg";
import book from "../sections/images/book2.webp";
import pdf from "../sections/images/The-Real-Raw-Truth-About-Solar.pdf";
import axios from 'axios';
import { Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from "react-scroll";
import TruthForm from '../sections/partials/TruthForm';

const TruthAboutSolar = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const formRef = useRef(null);
    const pdfUrl = process.env.PUBLIC_URL + "/pdfs/The-Real-Raw-Truth-About-Solar.pdf";
  const [isLoading, setIsLoading] = useState(false);

    const sendEmail = (email, name) => {
        const emailData = new URLSearchParams();
        emailData.append('to_email', email);
        emailData.append('subject', 'Hello ' + name + ', Here\'s your Truth About Solar EBook!');
        emailData.append('message', 'Please find attached EBook pdf with this email. Thanks!');
        emailData.append('from_email', 'gdsa006@gmail.com');

        axios.post('/php/sendEmail.php', emailData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((response) => {
            console.log('Email sent successfully');
            // Handle success response
        })
            .catch((error) => {
                console.error('Error sending email:', error);
                // Handle error response
            });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/shell.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const name = event.target.firstname.value;
        sendEmail(email, name); // Call the sendEmail function to send the email
        document.getElementById("myHubSpotForm").submit(); // Submit the HubSpot form
        setFormSubmitted(true); // Set the formSubmitted state to true
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
    };

    const resetForm = () => {
        setFormSubmitted(false);
        formRef.current.reset();
    };
    return (
        <>
        <Helmet>
        <title>Truth</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <section className={`section ${truth.luminson__bold_section}`} id='hero'>
                <Container>
                    <h1>
                        FORGET EVERYTHING YOU KNOW ABOUT SOLAR
                    </h1>
                </Container>
            </section>
            <section className={`section ${truth.luminson__hero_section}`}>
                <Container>
                    <div className={truth.wrapper}>
                        <Row>
                            <Col lg={8} md={7} xs={12} className={truth.left}>
                                <h1>
                                    <span>DISCOVER</span> THE INSIDE SECRETS <span>"THE SOLAR BROS"</span> <span className={truth.textUnderline}>DO NOT</span> WANT YOU TO <span>KNOW</span>
                                </h1>
                                 
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        fill="#ffffff" stroke="none">
                                        <path d="M368 4898 c-21 -5 -58 -28 -83 -50 -65 -57 -79 -111 -70 -271 36
-684 211 -1229 570 -1772 513 -776 1352 -1323 2258 -1474 228 -39 292 -43 711
-48 l418 -5 -360 -362 c-198 -198 -367 -375 -376 -392 -43 -87 -28 -175 43
-245 72 -73 159 -86 249 -39 21 10 287 270 593 577 597 599 583 582 582 684 0
25 -8 61 -18 80 -24 48 -1131 1157 -1174 1175 -48 20 -134 18 -180 -6 -88 -45
-134 -144 -111 -242 12 -52 23 -63 383 -425 l371 -373 -369 0 c-389 0 -518 9
-740 51 -585 110 -1123 396 -1550 824 -224 224 -406 474 -547 750 -204 403
-304 788 -326 1260 -7 150 -9 164 -35 208 -47 79 -149 120 -239 95z"/>
                                    </g>
                                </svg>

                            </Col>
                            <Col lg={4} md={5} xs={12}>
                                <p className={truth.aboveFormText}>Download Your <span>Free</span> Copy of our short ebook. <span>The Real Truth About Solar In 2023.</span></p>
                                <TruthForm setShowModal={setShowModal} setFormSubmitted={setFormSubmitted} />
                                <small>We are committed to keeping your e-mail address confidential and respect your privacy.</small>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            {formSubmitted && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Email Sent!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please check your email for the Truth About Solar EBook or click
                        <a href={pdfUrl} target='_blank'> here</a> to download it right now.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <section className={`section ${truth.luminson__book_section}`}>
                <Container>
                    <div className={truth.wrapper}>
                        <Row>
                            <Col lg={4} md={4} xs={12} order={2} className={`order-lg-1 ${truth.luminson__book_wrapper}`}>
                                {/* Content for the second column */}

                                <Image src={book} className={`${truth.luminson__book}`} />

                            </Col>
                            <Col lg={8} md={8} xs={12} order={1} className="order-lg-2">
                                <h1>
                                    In this <span>Tell-all Guide</span>, <br /><span className='d-none'>You'll Discover:</span>
                                </h1>
                                <ul className="custom-list">
                                    <li>
                                        We'll show you how avoid paying commissions and pushy salespeople.
                                   
                                                                       You will learn how  using Smart Home Energy Management Tech will make energy freedom possible in as little as 4-6 years!
                                    
                                                                          </li>
                                                                          <li>
                                                                          <span>We reveal information that will arm you with the knowledge</span> to save you tens of thousands of dollars.

                                                                          </li>
                                </ul>
                                <Link to="hero" smooth className={truth.button}>
    Download Your PDF Now
</Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default TruthAboutSolar;
