import terms from './Terms.module.css'
import Container from 'react-bootstrap/Container';
import SectionHeading from '../sections/partials/SectionHeading';
import { Helmet } from 'react-helmet';


const Terms = () => {
    return (
        <>
        <Helmet>
        <title>Terms &  Conditions</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <div className={`section ${terms.luminson__terms_section}`}>
                <Container>
                    <div className={terms.luminson_sectionheading}>
                        <SectionHeading heading="Terms of Use" sub="" align="text-center" />
                    </div>
                    <div className={terms.luminson__terms_content}>
                        <p className={terms.luminson__para}>
                            Luminson Energy, LLC (a Subsidiary of Luminson Companies, Inc). These terms and conditions ("Terms") apply to your use of our website and services. By accessing or using our website or services, you agree to these Terms. If you do not agree to these Terms, you may not use our website or services.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            Use of Our Website and Services
                        </p>
                        <p className={terms.luminson__para}>
                            Luminson Companies, Inc. provides various services and resources through our website, which are subject to these Terms. We reserve the right to modify, suspend, or terminate our website or services at any time without notice.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            Intellectual Property
                        </p>
                        <p className={terms.luminson__para}>
                            All content on our website, including text, graphics, logos, images, and software, is the property of Luminson Companies, Inc. or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, copy, distribute, or modify our content without our prior written consent.                        </p>
                        <p className={terms.luminson__list_heading}>
                            User Content
                        </p>
                        <p className={terms.luminson__para}>
                            You may submit content to our website or services, such as comments, reviews, or feedback. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and irrevocable right to use, modify, and distribute your content for any purpose.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            Disclaimers and Limitations of Liability
                        </p>
                        <p className={terms.luminson__para}>
                            Our website and services are provided on an "as is" and "as available" basis, without warranties of any kind, express or implied. We make no warranties or representations about the accuracy, completeness, or reliability of our content or services. We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or services.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            Indemnification
                        </p>
                        <p className={terms.luminson__para}>
                            You agree to indemnify and hold Luminson Companies, Inc. and its affiliates, officers, directors, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our website or services, your violation of these Terms, or your violation of any rights of another.                        </p>
                        <p className={terms.luminson__list_heading}>
                            Governing Law and Jurisdiction
                        </p>
                        <p className={terms.luminson__para}>
                            These Terms and your use of our website and services are governed by the laws of the State of California, without regard to its conflict of laws provisions. Any dispute arising out of these Terms or your use of our website or services shall be resolved exclusively in the state or federal courts located in Los Angeles County, California.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            Miscellaneous
                        </p>
                        <p className={terms.luminson__para}>
                            These Terms constitute the entire agreement between you and Luminson Companies, Inc. regarding your use of our website and services. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.</p>
                        <p className={terms.luminson__list_heading}>
                            Acceptance of Terms
                        </p>
                        <p className={terms.luminson__para}>
                            By using this website, you are hereby accepting the terms and conditions stipulated within the Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you agree and accept such changes.
                        </p>
                        <p className={terms.luminson__list_heading}>
                            How to Contact Us
                        </p>
                        <p className={terms.luminson__para}>
                            If you have any questions or concerns regarding the Terms and Condition as related to our website, please feel free to contact us at the following email, telephone number or mailing address.</p>
                        <p className={terms.luminson__para_heading}>
                            Email: LegalTeam@luminson.com
                        </p>
                        <p className={terms.luminson__para}>
                            Telephone Number: 1-877-299-4943
                        </p>
                        <address className={terms.luminson__para}>
                            Mailing Address:<br />
                            Luminson, Inc<br />
                            1120 S Rackham Way<br />
                            Suite 300<br />
                            Meridian, Idaho<br />
                            83642
                        </address>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Terms;