import expertadvice from './ExpertAdvice.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Expert from "./images/expert-advice.webp";
import PrimaryButton from '../elements/PrimaryButton';
import SectionHeading from './partials/SectionHeading';
import { HiOutlineDeviceMobile } from "react-icons/hi";

const ExpertAdvice = () => {
    return (
        <>
            <div className={`section ${expertadvice.luminson__expertadvice_section}`}>
                <Container>
                    <Row md={12}>
                    <Col md={6} className={`d-flex align-items-center justify-content-center order-12 order-md-1 ${expertadvice.luminson__expertadvice_caption}`}>
                            <div className={expertadvice.luminson__expertadvice_card}>
                                <SectionHeading heading="Expert advice is at your fingertips." sub="" align="text-center" />
                                <p>
                                    Our Home Energy Advisors are available to answer any of your Solar and Home Energy Questions
                                </p>
                                <div className={expertadvice.luminson__contact}>
                                    <div className={` ${expertadvice.luminson__contact_icon}`}>
                                        <span className={expertadvice.luminson__contact_icon_image}>
                                            <HiOutlineDeviceMobile />
                                        </span>
                                    </div>
                                    <div className={`${expertadvice.luminson__contact_icon}`}>
                                        <span className={expertadvice.luminson__contact_icon_text}>Call or Text Us</span>
                                        <span className={expertadvice.luminson__contact_icon_text}><a href="tel:8444040505" style={{'color': '#212529', 'textDecoration': 'none'}}>(844)404-0505</a></span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='order-1 order-md-12'>
                            <div className={expertadvice.luminson__expertadvice_image}>
                                <Image src={Expert} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ExpertAdvice;