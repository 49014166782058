import Slider from "react-slick";
import ReviewCard from './ReviewCard';

const ReviewSlider = (props) => {
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const reviews = [
        {
            name: 'Jeff Gargliano Meridian, ID',
            review: 'This is how Solar should be, Wish I would have pulled the trigger sooner',
            moy: 'April 2021'
        },
        {
            name: 'Joe Ledger Austin, TX',
            review: 'We were amazed at the savings, Luminson Explained things very thoroughly',
            moy: 'June 2021'
        },
        {
            name: 'Deb Brown Harker Heights, TX',
            review: 'Great communication all the way through',
            moy: 'June 2021'
        },
        {
            name: 'Jennifer Barker Leander, TX',
            review: 'Such a great decision so far, they have exceeded our expectations',
            moy: 'Aug 2021'
        },
        {
            name: 'Steve Martinez Humble, TX',
            review: 'Everyone was so friendly and professional',
            moy: 'Dec 2021'
        },
        {
            name: 'Darren King, Meridian, ID',
            review: 'We love having a $5.21 power bill every month',
            moy: 'Jan 2022'
        },
        {
            name: 'Pam Kearse San Antonio, TX',
            review: 'Working with Drew has been wonderful, He addressed all of my concerns',
            moy: 'Feb 2022'
        },
        {
            name: 'Silvia Cecena New Braunfels, TX',
            review: 'We had no clue we could qualify for these savings, So grateful to you guys',
            moy: 'Oct 2022'
        },
        {
            name: 'Shaun Coxon Meridian, ID',
            review: 'It’s been great, We’re set up for the future. We own our power now',
            moy: 'Oct 2022'
        },
        {
            name: 'Jon Petrakos Meridian, ID',
            review: 'Everything is great, you’ve guys done a great job just as I expected',
            moy: 'Oct 2022'
        }
    ]

    return (
        <>
            <Slider {...settings}>
                {reviews.map(function (testimonial, i) {
                    return <ReviewCard name={testimonial.name} review={testimonial.review} moy={testimonial.moy} key={i} />;
                })}
            </Slider>
        </>
    );
}

export default ReviewSlider;