import stepcard from './StepCard.module.css'
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'

const StepCard = (props) => {

    const columns = props.cols;
    const title = props.title;
    const text = props.text;
    const number = props.number;

    return (
        <>
            <Col md={columns} className={stepcard.luminson__card_block}>
                <Card className={`${stepcard.luminson__single_card}`} >
                    <span className={stepcard.luminson__number}>{number}</span>
                    <Card.Body>
                        <Card.Title><h3>{title}</h3></Card.Title>
                        <Card.Text>{text}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}

export default StepCard;