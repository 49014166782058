import sectionheading from './SectionHeading.module.css'

const SectionHeading = (props) => {

    const heading = props.heading;
    const sub = props.sub;
    const align = props.align;

    return (
        <>
            <div className={`${sectionheading.luminson__section_heading} ${align}`} >
                <h1 className={sectionheading.luminson__heading}>{heading}</h1>
                <h5 className={sectionheading.luminson__sub}>{sub}</h5>
            </div>
        </>
    );
}

export default SectionHeading;