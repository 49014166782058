import leader from './Leader.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import SectionHeading from './partials/SectionHeading';
import PrimaryButton from '../elements/PrimaryButton';
import TwoColSection from './partials/TwoColSection';

const Leader = () => {
    return (
        <>
            <div className={`section ${leader.luminson__leader_section}`}>
                <Container>
                    <TwoColSection flip={false} textAlign='center' image='leader-image' heading='A Leading Solar Energy Provider You Can Trust' text={<><p> At Luminson Energy, We are committed to finding the best solution for you and your family to save the most money possible. Our approach is different. </p></>} buttonText='' buttonColor='green' />
                </Container>
            </div>
        </>
    );
}

export default Leader;