import startanalysis from './StartAnalysis.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeading from '../sections/partials/SectionHeading';
import MultiStep from '../sections/partials/MultiStep';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const StartAnalysis = () => {
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const visitorid = queryParams.get('visitorid');
    return (
        <>
        <Helmet>
        <title>Start Analysis</title>
        <meta name="description" content="Your page description" />
      </Helmet>
           <MultiStep visitorid={visitorid}/>
        </>
    );
}

export default StartAnalysis;