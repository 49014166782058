import reviewcard from './ReviewCard.module.css'
import Card from 'react-bootstrap/Card'

const ReviewCard = (props) => {
    return (
        <Card className={reviewcard.luminson__card} >
            <div className={reviewcard.luminson__card_review} >
                <p>"{props.review}"</p>
            </div>
            <div className={reviewcard.luminson__card_name} >
                <h5>{props.name}</h5>
            </div>
        </Card>
    );
}

export default ReviewCard;