import React from 'react';
import { InlineWidget } from 'react-calendly';
import page from './MyCalendlyComponent.module.css';

const MyCalendlyComponent = () => {
  return (
    <div className={page.calendlyContainer}>
      <div className={`row ${page.calendlyRow}`}>
        <div className={`col ${page.calendlyCol}`}>
          <div className={page.calendlyInlineWidget}>
            <InlineWidget
              url="https://meetings.hubspot.com/luminson/partner-page-schedule?embed=true"
              styles={{
                height: '100%',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCalendlyComponent;
