import primarybutton from './PrimaryButton.module.css'
import Button from 'react-bootstrap/Button';
import { Link, Route, Routes } from 'react-router-dom';

const PrimaryButton = props => {
    const name = props.name;
    const theme = props.theme;
    const icon = props.icon;
    const link = props.link;
    return (
        <>
            <Link to={link}>
                <Button className={`${primarybutton.luminson___primary_btn} ${primarybutton[theme]}`}>{name}<span className={primarybutton.luminson__btn_icon}>{icon}</span></Button>
            </Link>
        </>
    );
}

export default PrimaryButton;