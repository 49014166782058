import blog from './Blog.module.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import SectionHeading from '../sections/partials/SectionHeading';
import HeroImage from '../sections/images/heroImage.webp';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Post from '../sections/partials/Post';
import { Helmet } from 'react-helmet';

const Blog = () => {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(3);

    const loadData = (e) => {
        e.preventDefault();
        setLoad((prev) => prev + 3)
    }

    useEffect(() => {
        axios.get(`https://public-api.wordpress.com/wp/v2/sites/luminson.wordpress.com/posts`)
            .then(result => {
                setData(result.data);
                console.log(result.data);
            })
            .catch(console.error)
    }, []);

    return (
        <>
        <Helmet>
        <title>Blog</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <div className={`section ${blog.luminson__blog_section}`}>
                <Container>
                    <div className={blog.luminson_sectionheading}>
                        <SectionHeading heading="Blog" sub="" align="text-center" />
                    </div>
                    <Row>
                        {data.slice(0, load).map((data) => (
                            <Post image={data.jetpack_featured_media_url} title={data.title.rendered} desc={data.excerpt.rendered} date={data.date} slug={data.slug} />
                        ))}
                    </Row>
                    <div className='text-center mt-4'>
                        {
                            load < data.length && <button className={blog.luminson_loadMore} onClick={loadData}>Load more...</button>
                        }
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Blog;