import forgetsolar from './ForgetSolar.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import PrimaryButton from '../elements/PrimaryButton';
import SectionHeading from './partials/SectionHeading';
import TwoColSection from './partials/TwoColSection';

const ForgetSolar = () => {
    return (
        <>
            <div className={`section ${forgetsolar.luminson__forgetsolar_section}`}>
                <Container>
                    <TwoColSection flip={true} textAlign='center' image='forget-solar' heading='A Completely Different Approach to Solar' text={<><p> By first ensuring your home's efficiency is maximized, Many times we can reduce the number of solar panels needed. You guessed it, Saving you more money. </p></>} buttonText='Learn More' buttonColor='beige' />
                </Container>
            </div>
        </>
    );
}

export default ForgetSolar;