import React from 'react';
import styles from './NotFoundPage.module.css';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <>
     <Helmet>
        <title>404</title>
        <meta name="description" content="Your page description" />
      </Helmet>
    
    <div className={styles.container}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.text}>Oops! The page you're looking for doesn't exist.</p>
      <p className={styles.text}>Please check the URL or go back to the homepage.</p>
      <a href="/" className={styles.link}>Go to Homepage</a>
    </div>
    </>
  );
}

export default NotFoundPage;
