import wedifferent from './WeDifferent.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DisplayCard from './partials/DisplayCard';
import SectionHeading from './partials/SectionHeading';

const WeDifferent = () => {
    return (
        <>
            <div className={`section ${wedifferent.luminson__wedifferent_section}`} id='WeAreDifferent'>
                <Container>
                    <div className={wedifferent.luminson_sectionheading}>
                        <SectionHeading heading={<>The Luminson Energy Difference</>} sub="" align="text-center" />
                    </div>
                    <Row md={12}>
                        <DisplayCard cols='6' title='Much Smaller Company Overhead' theme='light' icon='Much_Smaller_Company_Overhead_A_Bold' text={
                            <>
                                <ul>
                                    <li>The Majority of our workforce works remotely from The Convenience of their own home</li>
                                    <li>We leverage cutting edge technology of a Cloud-Based Office to cut costs wherever we can so we can save you money! </li>
                                    <li>No Company Vehicle Wraps or Uniforms to buy.</li>
                                    <li>By working Virtually and over the phone we pass those savings along to you</li>
                                    <li>Privately Held, Corporation with no Investors or Shareholders to payout</li>
                                </ul>
                            </>
                        } />

                        <DisplayCard cols='6' title='No Large Commissions by Maximizing System Sizes' theme='light' icon='No_Large_Commissions_by_Maximizing_System_Sizes_A_Bold' text={
                            <>
                                <ul>
                                    <li>Our Certified Home Energy Advisors are compensated a flat rate based off the quality of your Overall Experience.</li>
                                    <li>By Operating Virtually and Leveraging Technology we can Save on Travel expenses to save you more and have better communication!</li>
                                    <li>Our Advisors Truly have Your Best Interest in mind. Advising on what would be the best solution for your specific home needs.</li>
                                    <li>We take the approach of making sure that your home is operating at maximum efficiency to minimize the amount of solar you need to purchase based off of your needs.</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='6' title='Better Financing Options' theme='light' icon='Better_Financing_Options_A_Bold' text={
                            <>
                                <ul>
                                    <li>Because of our Unique Model, We have partnered with Funding sources that have low interest rates without all of the hidden fees</li>
                                    <li>We do our best to find you the best option for you to save the most money</li>
                                    <li>We help you Own your Power and build equity every month till your system is paid off</li>
                                    <li>Many times we Can Bundle the Home Improvements needed in one low monthly payment</li>
                                </ul>
                            </>
                        } />
                        <DisplayCard cols='6' title='We Partnered With The Best Local Installers' theme='light' icon='We_Partnered_with_The_Best_Local_Installer_A_Bold' text={
                            <>
                                <ul>
                                    <li>Our Strenuous Vetting Process, Ensures you have the best local Installing Team to Save you All the hassle of Finding them yourself. </li>
                                    <li>We make sure to only partner with Reliable, Proven Professional Installers that you can trust to get the job done right.</li>
                                    <li>By choosing only reputable local installers we can get your Install Time down by as much as 50%</li>
                                </ul>
                            </>
                        } />
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default WeDifferent;