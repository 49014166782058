import React from 'react';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import post from './Post.module.css';
import { parseDate } from '../../../helpers/parseDate';

const Post = (props) => {
    const parse = require('html-react-parser');

    const getDescription = () => {
        const description = props.desc
            .replace(/<figure[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '')
            .replace('</figure>', '')
            .replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '')
            .replace(/\n\n/g, '');
        return parse(description.substring(0, 40) + '...');
    };

    return (
        <>
            <Col md={4}>
                <Link to={'/blog/' + props.slug} className={post.luminson__post_slug}>
                    <div
                        className={`${post.luminson__post_card} ${props.mode === 'related-posts' ? post.luminson__related_post : ''
                            }`}
                    >
                        <div
                            className={`${post.luminson__post_card_image} ${props.mode === 'related-posts' ? post.luminson__related_image : ''
                                }`}
                        >
                            <Image src={props.image} />
                        </div>
                        <div
                            className={`${post.luminson__post_card_title} ${props.mode === 'related-posts' ? post.luminson__related_title : ''
                                }`}
                        >
                            <h4>{parse(props.title)}</h4>
                        </div>

                        <div
                            className={`${post.luminson__post_card_desc} ${props.mode === 'related-posts' ? post.luminson__related_desc : ''
                                }`}
                        >
                            {getDescription()}
                        </div>
                    </div>
                </Link>
            </Col>
        </>
    );
};

export default Post;
