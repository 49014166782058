import { useState } from 'react';

import displaycard from './DisplayCard.module.css'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import cn from "classnames";
import Icon from './Icon';
import { FaPlus } from "react-icons/fa";

const FlipCard = (props) => {
    const [showBack, setShowBack] = useState(false);

    function handleClick() {
        if (props.card.variant === "click") {
            setShowBack(!showBack);
        }
    }

    function handleFocus() {
        if (props.card.variant === "focus") {
            setShowBack(true);
        }
    }

    function handleBlur() {
        if (props.card.variant === "focus") {
            setShowBack(false);
        }
    }

    const columns = props.cols;
    const title = props.title;
    const theme = props.theme;
    // style={ props.card.looks == 'full' ? {backgroundImage: "url(./images/icons/Outlined/" + props.card.front + ".svg)"} : {backgroundImage: 'red'}}
    return (
        <>
            <Col lg={columns} md={6} xs={12} className={displaycard.luminson__card_block}>
                <div className={displaycard.luminson__title}><h3>{title}</h3></div>
                <div className={displaycard.luminson__card_wrapper}>
                    <Card className={`${displaycard.luminson__single_card} ${displaycard[theme]}`} >
                        <span className={`icon ${displaycard.luminson__plus_icon} `}><FaPlus /></span>

                        <div className={cn("flip-card-outer", {
                            "focus-trigger": props.card.variant === "focus"
                        })}
                            onClick={handleClick}
                            onMouseEnter={handleFocus}
                            onMouseLeave={handleBlur}
                        >
                            <div className={cn("flip-card-inner", {
                                showBack,
                                "hover-triggered": props.card.variant === "hover"
                            })}>
                                <div className="card front">
                                    <div className={`card-body d-flex justify-content-center align-items-center p-0`}>
                                        <div className={`${displaycard.luminson__icon} ${displaycard[props.card.looks]}`}>
                                            {props.card.looks === 'full' ?
                                                <Icon icon={props.card.front} style={props.card.looks === 'full' ? { transform: "scale(1.2)" } : { transform: "scale(1.0)" }} />
                                                :
                                                <Icon icon={props.card.front} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="card back">
                                    <div className="card-body d-flex justify-content-center align-items-center">
                                        <p className="card-text fs-1 fw-bold">{props.card.back}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
        </>
    );
}

export default FlipCard;