import certifiedadvisors from './CertifiedAdvisors.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import PrimaryButton from '../elements/PrimaryButton';
import SectionHeading from './partials/SectionHeading';
import TwoColSection from './partials/TwoColSection';

const CertifiedAdvisors = () => {

    return (
        <>
       

                    <div className={`section ${certifiedadvisors.luminson__certifiedadvisors_section}`}>
                        <Container>
                                <TwoColSection flip={false} textAlign='center' image='certified-advisor' heading='Our Certified Home Energy Advisors Are Here to Help You.' text={<><p> We understand that going solar is a big decision and You want to make sure you are making the best choice for your home. Our team is formally trained and certified to advise you on how to save the most money possible. Our team isn't here to just sell solar, but rather to ensure that you are well informed and equipped with the latest technology in home energy solutions. </p></>} buttonText='' buttonColor='beige' />
                        </Container>
                    </div>

        </>
    );
}

export default CertifiedAdvisors;