import finalanalysis from './FinalAnalysis.module.css'

import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import React, { useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import TextTransition, { presets } from "react-text-transition";
import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import ReactGoogleMapImage from 'react-google-map-image';
import Geocode from "react-geocode";
import Modal from 'react-bootstrap/Modal';
import SolarIcon from '../images/animation.gif';
import Image from 'react-bootstrap/Image';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";


const FinalAnalysis = ({ nextStep, handleFormData, prevStep, values, visitorid }) => {

  const [value, setValue] = React.useState(25);
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [data, setData] = React.useState([]);
  const [html, setHTML] = useState({ __html: "" });
  const [newaddress, setNewAddress] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const potential_savings = 185;
  const fixed_value = 480;
  const [formErrors, setFormErrors] = useState({});
  
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentState = localStorage.getItem('state');
  const [isLoading, setIsLoading] = useState(false);

  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    setAnimation(false);
    setTimeout(() => {
      nextStep();
    }, 20000)
  };



  const handleRecaptchaChange = (value) => {
    console.log("reCAPTCHA Value:", value);
    setRecaptchaValue(value);
  };


  // var retrievedObject = localStorage.getItem('newLocation');
  // if(retrievedObject){
  // let lat = JSON.parse(retrievedObject).lat;
  // let lng = JSON.parse(retrievedObject).lng;
  // }
  // Geocode.setApiKey("AIzaSyBsOkp1Zli2_QpVA4vvrWKiHB7-lBAiQXo");
  // Geocode.setLanguage("en");


  // Geocode.fromLatLng(lat, lng).then(
  //     (response) => {
  //       const address = response.results[0].formatted_address;
  //       console.log('0000000000000');
  //       console.log(address);
  //       address ? setNewAddress(address) : '0';
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IjV5QzVwMDlpcDJmelF4bWk3S0RkIiwiY29tcGFueV9pZCI6IlQ4V3RMS2l1ckdQaEppQldmVUZLIiwidmVyc2lvbiI6MSwiaWF0IjoxNjc3NTYzNjQ1MjE1LCJzdWIiOiJ1c2VyX2lkIn0.en8qOcyvHrErm6e6F-23vdEwcolU4ZonM6HBmmKWhVI'
  };
  const googleMapApiConfig = {
    center: values.address,
    size: '500x240',
    zoom: '15',
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    maptype: 'satellite',
    position: {
      lat: 11.77,
      lng: -122.42
    }
  }


  useEffect(() => {
    async function createMarkup() {
      let response;
      let url = `https://sunroof.withgoogle.com/building/40.6561518/-74.00443369999999/#?f=buy`;
      response = await fetch(url, {
        method: 'GET',
        crossDomain: true,
        headers: { 'Content-Type': 'application/json' },
        //Request Type
        //method: 'POST',
      })
      const backendHtmlString = await response.text()
      console.log('asdsad');
      console.log(backendHtmlString)
      return { __html: backendHtmlString };
    }
    createMarkup().then(result => setHTML(result));
  }, []);


  const [alldata, setAllData] = React.useState({
    email: '',
    phone: '',
    name: '',
    address: values.address,
    power_bill: values.powerbill ? '$' + values.powerbill : '$180'

  })

  const [additionalData, setAdditionalData] = React.useState({
    name: '',
    email: '',
    phone: '',
    clickid: visitorid,
    publisherid: "77459191-177e-4c4d-8579-50da34bd86ac", // Replace with the actual value
  });



  //   address: values.address,
  //   "power_bill": '$' + values.powerbill
  // }
  const handleNameChange = event => {
    setAllData({ ...alldata, name: event });
    setAdditionalData({ ...additionalData, name: event });

    console.log(alldata.name);
  }

  const handleEmailChange = event => {
    setAllData({ ...alldata, email: event });
    setAdditionalData({ ...additionalData, email: event });
    console.log(alldata.email);
  }

  const handleMobileChange = event => {
    const numericValue = event.replace(/\D/g, '').slice(0, 10);
    setAllData({ ...alldata, phone: numericValue });
    setAdditionalData({ ...additionalData, phone: numericValue });
    console.log(alldata.phone);
  }



  const handleSubmit = (event) => {
    console.log(alldata);
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);

    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    // console.log(token);
    const form = event.currentTarget;


    setAllData({ ...alldata, phone: alldata.phone, email: alldata.email });
    setAdditionalData({ ...additionalData, phone: alldata.phone, email: alldata.email, name: alldata.name });

    axios
      .post("https://leads.luminson.com/api/energy-analysis-form", alldata, {
        headers: headers
      })
      .then(res => {
        console.log('success');
        console.log(res);
        console.log(res.data);
        setLoading(false);
        setShow(false);
        setShow1(true);

        axios
      .post("https://leads.luminson.com/api/leads", additionalData, {
        headers: headers,
      })
      .then((res) => {
        console.log('Additional API call success');
        console.log(res);
        console.log(res.data);
        // Handle the response as needed
      })
      .catch(err => {
        console.log(err);
        setLoading(false);

        // Check if the error response contains specific error information
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;

          // Check if the error message indicates an incorrect email
          if (errorMessage.toLowerCase().includes('incorrect email')) {
            setFormErrors({ email: 'Incorrect email' });
          } else {
            // Handle other error messages as needed
            // For example, set a generic error message
            setFormErrors({ generic: errorMessage });
          }
        } else {
          // Handle other types of errors here
          // For example, if the error response structure is different
        }
      });

      
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log('->>>');
    console.log(additionalData);

    
  }

  return (
    <>
      <div>
        <Card style={{ border: 0 }}>
          <Card.Body>
            <div className={finalanalysis.luminson__heading}>
              <h3>Congratulations, it looks like your home qualifies for solar.</h3>
            </div>
            <div className={finalanalysis.luminson__text}>
              <span className='d-none hidden'>{currentState && currentState + ' homeowner,'}</span>
              <h3>
                You could save between $79-185+ per month when going solar and following recommended low-cost energy suggestions with Luminson Energy.
              </h3>
            </div>
            <Row md={12} className={finalanalysis.luminson__cols}>
              <Col md={8} className={finalanalysis.luminson__cols_left}>
                <ReactGoogleMapImage
                  config={googleMapApiConfig}

                  wrapperStyle={{ width: '100%' }}
                  style={{ width: '100%', height: 'auto', border: '1px solid #ccc' }}
                />
                <h5>{values.address}</h5>
              </Col>
              <Col md={4} className=''>
                <div className={finalanalysis.luminson__data}>
                  <h5>
                    Current Power Bill: ${values.powerbill ? values.powerbill : '180'}
                  </h5>
                  <h5>Monthly Savings: <span> ${potential_savings}</span></h5>
                  <h5>Future Savings: ${(potential_savings) * fixed_value}</h5>
                </div>
                <div className={finalanalysis.luminson__button}>
                  <Link onClick={handleShow}>Unlock My Special Pricing</Link>

                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className={finalanalysis.luminson__modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body className={finalanalysis.luminson__modal_body}>
          <div className={finalanalysis.luminson__modal_title}>
            <h1>Unlock My Special Pricing</h1>
            <h5>Enter your information to maximize your savings.</h5>
          </div>
          <div>
            <Row md={12} className={finalanalysis.luminson__modal_cols}>

              <Col md={12} className={finalanalysis.luminson__cols_right}>
                <div className={finalanalysis.luminson__modal_form}>
                  <div className='mb-4'>
                    <h6>Installation Address</h6>
                    {values.address}
                  </div>
                  <Form name="estimateForm" onSubmit={handleSubmit}>
                    <Form.Group className={`mb-4 ${finalanalysis.luminson__field}`}>
                      <Form.Control
                        type="text"
                        className='form-control'
                        placeholder="Enter your name*"
                        name="name"
                        defaultValue=''
                        onChange={(e) => handleNameChange(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className={`mb-4 ${finalanalysis.luminson__field}`}>
                      <Form.Control
                        type="email"
                        className='form-control'
                        placeholder="Enter your email address*"
                        name="email"
                        defaultValue=''
                        onChange={(e) => handleEmailChange(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className={`mb-4 ${finalanalysis.luminson__field}`}>
                      <Form.Control
                        type="tel"
                        className='form-control'
                        placeholder="Enter your mobile number*"
                        name="mobile"
                        defaultValue=''
                        onChange={(e) => handleMobileChange(e.target.value)}
                        required
                        pattern="[0-9]{10}" // Specify the pattern for a 10-digit mobile number
                        title="Please enter a 10-digit mobile number" // Displayed if pattern doesn't match
                      />
                    </Form.Group>
    
                    <Form.Group className={`mb-4 ${finalanalysis.luminson__field}`}>

      <ReCAPTCHA
        sitekey="6Lfs8VkoAAAAAFVMdk6mKxdYsoRS2mnu40tSH97z"
        onChange={handleRecaptchaChange}
      />
                          </Form.Group>

                    <Button className={`${finalanalysis.luminson__continue_button}`} type="submit" id='google_analytics_btn' disabled={!recaptchaValue}>
                      {isLoading ? 'Loading...' : 'Submit'}

                    </Button>
                    {formErrors.generic && <p className="text-danger">{formErrors.generic}</p>}
                    {formErrors.email && <p className="text-danger">{formErrors.email}</p>}
                  </Form>
                  <p style={{ fontSize: '0.6rem', marginTop: '1.5rem', display: 'block' }}>
                    *We'll help you get your special pricing and promise not to spam you. By clicking â€œUnlock My Pricing,â€ I authorize Luminson CompaniesÂ® to email, call, or text me about its products and services at the number Iâ€™ve provided, using pre-recorded messages or automatic dialer, even if my number is on a â€œDO NOT CALLâ€ list. I understand that my consent is not a condition of purchase, and that I may opt-out anytime. Message and data rates may apply.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className='text-center'>
          <h5 className={`${finalanalysis.luminson__thank_you}`}>Your Special Pricing is On It's Way!</h5>
          <br />
          <Link to='/'>
            <Button className={`mb-5 ${finalanalysis.luminson__continue_button}`} >Close</Button>
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FinalAnalysis;