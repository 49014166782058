import multistep from './MultiStep.module.css'
import Card from 'react-bootstrap/Card'
import {Container, Col, Row} from 'react-bootstrap';
import LocationAddress from './LocationAddress';
import React, { useState } from "react";
import RoofConfirm from './RoofConfirm';
import { LoadScript } from "@react-google-maps/api";
import PowerBill from './PowerBill';
import FuturePlans from './FuturePlans';
import MajorAppliances from './MajorAppliances';
import FinalAnalysis from './FinalAnalysis';


const MultiStep = (props) => {
    //state for steps
    const [step, setstep] = useState(1);

    //state for form data
    const [formData, setFormData] = useState({
        address: "",
        places: "",
        powerbill: "",
        appliance: "",
        futureplans: []
    })
console.log(formData);

    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        setstep(step + 1);
    };

    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
        setstep(step - 1);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = input => e => {
        // input value from the form
        console.log(input);

        const { value } = e.target;


        //updating for data state taking previous state and then adding new value to create new object
        setFormData(prevState => ({
            ...prevState,
            [input]: value,
        }));
    }


    // javascript switch case to show different form in each step
    switch (step) {
        // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 1:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}} className={multistep.column}>
                                <LocationAddress nextStep={nextStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
        // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 2:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}}  className={multistep.column}>
                                <RoofConfirm nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
        // Only formData is passed as prop to show the final value at form submit
        
            case 3:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}} className={multistep.column}>
                                <PowerBill nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
            case 4:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}}  className={multistep.column}>
                                <MajorAppliances nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
            case 5:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}} className={multistep.column}>
                                <FuturePlans nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
            case 6:
            return (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div className="App">
                    <Container>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }} md={{span: 12, offset: 0}} xs={{span: 12, offset: 0}} className={multistep.column}>
                                <FinalAnalysis visitorid={props.visitorid} nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                </LoadScript>
            );
        // default case to show nothing
        default:
            return (
                <div className="App">
                </div>
            );
    }
}

export default MultiStep; 