import Card from 'react-bootstrap/Card'

const Icon = (props) => {

    return (

        <>
            <Card.Img src={('./images/icons/Outlined/' + props.icon + '.svg')} alt={props.icon} style={props.style} />
        </>
    );
}

export default Icon;