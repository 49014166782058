import process from './Process.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SectionHeading from './partials/SectionHeading';
import PrimaryButton from '../elements/PrimaryButton';
import StepCard from './partials/StepCard';

const Process = () => {
    return (
        <>
            <div className={`section ${process.luminson__process_section}`} id='HowItWorks'>
                <Container>
                    <div className={process.luminson_sectionheading}>
                        <SectionHeading heading="Our Process Puts You in Control" sub="Our technology makes it easy to achieve your home energy goals." align="text-center" />
                    </div>
                    <Row md={12}>
                        <StepCard cols='4' title='Start your Energy Analysis' number='1' text={
                            <>
                                <p>
                                    Our Certified Team of Advisors will Review your Questionnaire and Assess what Improvements can help your home be more efficient first.
                                </p>
                            </>
                        } />
                        <StepCard cols='4' title='Send Us Your Power Bill' number='2' text={
                            <>
                                <p>
                                    Our Proprietary Engineering Software will create you a Saving Report for you to review with your assigned Energy Advisor.
                                </p>
                            </>
                        } />
                        <StepCard cols='4' title='Review Savings Report' number='3' text={
                            <>
                                <p>
                                    Agree on terms of Financing and we will do all the rest. No heavy lifting on your Part. We got it from here. 
                                </p>
                            </>
                        } />
                    </Row>
                    <div className={process.luminson__button}>
                    <PrimaryButton theme="green" name={<>START MY ENERGY SAVINGS ANALYSIS</>} link='/start-analysis/' />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Process;