import testimonials from './Testimonials.module.css'
import Container from 'react-bootstrap/Container';
import SectionHeading from './partials/SectionHeading';
import ReviewSlider from './partials/ReviewSlider';


const Testimonials = () => {
    return (
        <>
            <div className={`section ${testimonials.luminson__testimonials_section}`}>
                <Container>
                    <div className={testimonials.luminson_sectionheading}>
                        <SectionHeading heading=" Our Clients are some of the Happiest in the Industry" sub="Here is what some of them are saying about our service." align="text-center" />
                    </div>
                    <div className={testimonials.luminson_review_slider}>
                        <ReviewSlider />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Testimonials;