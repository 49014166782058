import medialogos from './MediaLogos.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BrandLogo from './partials/BrandLogo';
import Carousel from 'react-bootstrap/Carousel'

const MediaLogos = () => {
    return (
        <>
            <div className={`section ${medialogos.luminson__medialogos_section}`}>
                <Container>
                    <Row md={12} className={medialogos.luminson__static_logos}>
                        <BrandLogo logo='brand-logo-1.webp' />
                        <BrandLogo logo='brand-logo-2.svg' />
                        <BrandLogo logo='brand-logo-3.webp' />
                        <BrandLogo logo='brand-logo-4.webp' />
                        <BrandLogo logo='brand-logo-5.svg' />
                    </Row>
                    <Carousel fade controls={false} indicators={false} className={medialogos.luminson__moving_logos}>
                        <Carousel.Item>
                            <BrandLogo logo='brand-logo-1.webp' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <BrandLogo logo='brand-logo-2.svg' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <BrandLogo logo='brand-logo-3.webp' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <BrandLogo logo='brand-logo-4.webp' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <BrandLogo logo='brand-logo-5.svg' />
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </div>
        </>
    );
}

export default MediaLogos;