import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import SectionHeading from '../sections/partials/SectionHeading';
import ReactPlayer from 'react-player';
import partners from './Partners.module.css';
import heroBG from "../sections/images/hero-bg.svg";
import { Image } from 'react-bootstrap';
import PlayIcon from '../sections/images/play-icon.png'; // Import the SVG play button icon
import first from "../sections/images/6466cb8125d22a0446a52bad.webp";
import second from "../sections/images/6466cc1f25d22ad221a52e93.webp";
import third from "../sections/images/64430aba25d62f6ed50d4cd3.webp";
import Card from 'react-bootstrap/Card';
import icon1 from "../sections/images/644ac09e3b5f5eb5e34dff92.svg";
import icon2 from "../sections/images/644ac116f2ebe55076ec454c.svg";
import icon3 from "../sections/images/644ac0aeb9b0d622de786143.svg";
import lead from "../sections/images/6466cd0cfdf8146140ef1595.webp";
import { FaPowerOff } from 'react-icons/fa'; // Import the desired Font Awesome icon
import logo1 from "../sections/images/646e533aae6e231d87a6e1a1.webp";
import logo2 from "../sections/images/646e5339e7a71d2a89af0c84.webp";
import logo3 from "../sections/images/646e533966abd901bfbd5ca4.webp";
import logo4 from "../sections/images/646e5339ae6e232b0da6e1a0.webp";
import logo5 from "../sections/images/646e5441ae6e230d0da6e23d.webp";
import logo6 from "../sections/images/646e5bdc66abd96198bd6b72.webp";
import logo7 from "../sections/images/646e5bdc66abd97bf3bd6b73.webp";
import logo8 from "../sections/images/646e539366abd99af1bd5ce8.webp";
import logo9 from "../sections/images/646e542f66abd9122fbd5d99.webp";
import logo10 from "../sections/images/646e543aae6e237746a6e239.webp";
import logo11 from "../sections/images/646e5539ae6e23122fa6e3a7.webp";
import logo12 from "../sections/images/646e558ae7a71dcf84af0fcc.svg";
import logo13 from "../sections/images/646e5d6f66abd958efbd6c7b.webp";
import logo14 from "../sections/images/646e5c3ce7a71df621af1b32.webp";
import logo15 from "../sections/images/646e5cae66abd90732bd6bc5.webp";
import wesection from "../sections/images/6466f926b9c42cdc9c45d070.webp";
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import MyCalendlyComponent from '../sections/MyCalendlyComponent';
import video from "../sections/images/partners_video.mp4";
import { Helmet } from 'react-helmet';



const Partners = () => {
    return (
        <>
        <Helmet>
        <title>Partners</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <div className={`section ${partners.luminson__hero_section}`}>
                <Image src={heroBG} className={`${partners.luminson__hero_section_background_image}`} />
                <Container className={`${partners.luminson__hero_section_container}`}>
                    <Row md={12} className={`d-flex align-items-center ${partners.luminson__hero_section_row}`}>
                        <Col md={6} className={`d-flex justify-content-center`}>
                            <div className={`${partners.luminson__hero_section_wrapper}`} >
                                <h1>A partnership with proven results.</h1>
                                <h5>Discover why partnering with Luminson Energy makes perfect sense for your business.</h5>
                            </div>
                        </Col>
                        <Col md={6} className={`${partners.video_container}`}>
                            <div className={partners.video_player}>
                                <ReactPlayer
                                    url={video}
                                    controls
                                    width="100%"
                                    height="100%"
                                    playIcon={<img src={PlayIcon} alt="Play" />} // Use the imported PlayIcon component
                                    light="black"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>




            <div className={`section ${partners.luminson__we_section}`}>
            <Image src={wesection}  className={`section ${partners.luminson__we_section_bg}`} />
                <Container>
                    <Row md={12} className={`${partners.luminson__we_row}`}>
                        <Col md={6} className={`text-center ${partners.luminson__we_section_image}`}>
                            <Image src={first} />
                        </Col>
                        <Col md={6} className={`text-center ${partners.luminson__we_section_text}`}>
                            <div className={`d-flex flex-column justify-content-center ${partners.luminson__we_section_text_wrapper}`} >
                                <h1>Your clients will thank you.</h1>
                                <p>When you recommend working with Luminson, You can have full confidence knowing that we will receive an excellent experience from start to finish.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row md={12} className={`${partners.luminson__we_section_row} ${partners.luminson__we_row}`}>
                    <Col md={6} className={`text-center order-md-last ${partners.luminson__we_section_image}`}>
                            <Image src={second} />
                        </Col>
                        <Col md={6} className={`text-center order-md-first  ${partners.luminson__we_section_text}`}>
                            <div className={`d-flex flex-column justify-content-center ${partners.luminson__we_section_text_wrapper}`} >
                                <h1>More Than Solar.</h1>
                                <p>Our team of non -commissioned, Certified Whole-Home Energy Advisors are educated and tested on the latest technology regarding whole-home energy efficiency, finance and the federally and locally available tax incentives. Meaning when you refer your clients to Luminson, They’ll get the absolute best recommendation of what will save them the most money.</p>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row md={12} className={`${partners.luminson__we_row}`}>
                        <Col md={6} className={`text-center ${partners.luminson__we_section_image}`}>
                            <Image src={third} />
                        </Col>
                        <Col md={6} className={`text-center ${partners.luminson__we_section_text}`}>
                            <div className={`d-flex flex-column justify-content-center ${partners.luminson__we_section_text_wrapper}`} >
                                <h1>Add Value to your Clients</h1>
                                <p>By strategically aligning your company with Luminson, Your clients will benefit from our whole-home energy professionals. We provide a comprehensive analysis completely for free on the best ways for your client to save the most money possible.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>






            <div className={`section ${partners.luminson__cards_section}`}>
                <Container>
                    <Row>
                        <Col md={4} className={`d-flex justify-content-center ${partners.column}`}>
                            <Card className={`shadow ${partners.luminson__cards_section_card}`}>
                                <Card.Body>
                                    <Image src={icon1} />
                                    <Card.Title className={` ${partners.luminson__cards_section_card_title}`}>A Very Lucrative Compensation Opportunity</Card.Title>
                                    <Card.Text>
                                        You have the ability to add Multiple 6+ Figure revenue by simply recommending Luminson to your clients.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className={`d-flex justify-content-center ${partners.column}`}>
                            <Card className={`shadow ${partners.luminson__cards_section_card}`}>
                                <Card.Body>
                                    <Image src={icon2} />
                                    <Card.Title className={` ${partners.luminson__cards_section_card_title}`}>We pass the savings on to your referral</Card.Title>
                                    <Card.Text>
                                        Our unique model of reduced overhead and no commission saves allows us to price thousands below our competition. As a result we are able to operate on a nominal fee and pass the savings to you and your referral.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className={`d-flex justify-content-center ${partners.column}`}>
                            <Card className={`shadow ${partners.luminson__cards_section_card}`}>
                                <Card.Body>
                                    <Image src={icon3} />
                                    <Card.Title className={` ${partners.luminson__cards_section_card_title}`}>We have America's Largest Installation Team</Card.Title>
                                    <Card.Text>
                                        We currently have 500+ Team Members Nationwide that are Dedicated to serving your clients
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>





            <div className={`section ${partners.luminson__stats_section}`}>
                <Container>
                    <Row>
                        <Col md={4} className={`d-flex flex-column text-center justify-content-center ${partners.column}`}>
                            <h4>
                                Average Compensation Per Referral
                            </h4>
                            <h1>
                                $1850+
                            </h1>
                        </Col>
                        <Col md={4} className={`d-flex flex-column text-center justify-content-center ${partners.column}`}>
                            <h4>
                                Average Referral Closing Percentage
                            </h4>
                            <h1>
                                63.74%
                            </h1>
                        </Col>
                        <Col md={4} className={`d-flex flex-column text-center justify-content-center ${partners.column}`}>
                            <h4>
                                Average Referral to Compensation Turn Around Time
                            </h4>
                            <h1>
                                57 Days
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>






            <div className={`section ${partners.luminson__lead_distribution_section}`}>
                <Image src={lead} className={`${partners.luminson__lead_distribution_image}`} />
                <Container>
                    <Row>
                        <Col md={6} className={`${partners.column}`}>
                            <div className={`d-flex flex-column text-center justify-content-center ${partners.luminson__lead_distribution_image_column}`}>
                                <div className={partners.h1}>
                                    We Provide Complete Transparency with Our <span>SMART LEAD Distribution™</span> Tool
                                </div>
                                <div className={partners.h4}>
                                    We utilize our powerful proprietary data algorithm that maximizes your referral conversions from lead intake to close.
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className={`d-flex flex-column text-center justify-content-center ${partners.column}`}>
                            <div className={`d-flex flex-column text-center justify-content-center ${partners.luminson__lead_distribution_image_column}`}>
                                <ul>
                                    <li>
                                        <div className={partners.h5}>
                                            <FaPowerOff className={partners.bulletIcon} />
                                            Custom Reporting and Updates on Referral Status.
                                        </div>
                                    </li>
                                    <li>
                                        <div className={partners.h5}>
                                            <FaPowerOff className={partners.bulletIcon} />
                                            Recorded Calls and Full Scripting available upon request.
                                        </div>
                                    </li>
                                    <li>
                                        <div className={partners.h5}>
                                            <FaPowerOff className={partners.bulletIcon} />
                                            Comprehensive Feedback Loop on reporting and Tracking of KPI's.
                                        </div>
                                    </li>
                                    <li>
                                        <div className={partners.h5}>
                                            <FaPowerOff className={partners.bulletIcon} />
                                            Full Accountability to track success and ensure your Referral is in the best hands from start to finish.
                                        </div>
                                    </li>
                                    <li>
                                        <div className={partners.h5}>
                                            <FaPowerOff className={partners.bulletIcon} />
                                            Lead Scoring System that ranks the quality of the lead from 1 to 100.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>




            <div className={`section ${partners.luminson__lead_mainline_section}`}>
                <Container>
                    <Row>
                        <div className={partners.h1}>
                            Over <u>2700</u> Installations. <br />Currently In <u>34</u> States Nationwide.
                        </div>
                    </Row>
                </Container>
            </div>



            <div className={`section ${partners.luminson__lead_companies_section}`}>
                <Container>
                    <div className={partners.h5}>
                        Some of the Companies We Work With
                    </div>
                    <Row>
                        <Col md={2} xs={4} className="offset-md-1">
                            <Image src={logo1} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo2} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo3} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo4} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo5} />
                        </Col>
                        
                        <Col md={2} xs={4} className="offset-md-1">
                            <Image src={logo6} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo7} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo8} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo9} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo10} />
                        </Col>

                        <Col md={2} xs={4} className="offset-md-1">
                            <Image src={logo11} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo12} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo13} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo14} />
                        </Col>
                        <Col md={2} xs={4}>
                            <Image src={logo15} />
                        </Col>
                    </Row>
                </Container>
            </div>




            <div id='schedule' className={`section ${partners.luminson__schedule_call_section}`}>
            <div className={`container ${partners.calendlyContainer}`}>
                    <div className={partners.h5}>
                    Schedule an Introductory Call
                    </div>
                        <MyCalendlyComponent />
                </div>
            </div>
        </>
    );
}

export default Partners;
