import displaycard from './DisplayCard.module.css'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import { FaPlus } from "react-icons/fa";

import Icon from './Icon';

const DisplayCard = (props) => {

    const columns = props.cols;
    const title = props.title;
    const image = props.icon;
    const text = props.text;
    const theme = props.theme;

    return (
        <>
            <Col lg={columns} md={6} xs={12} className={displaycard.luminson__card_block}>
                <div className={displaycard.luminson__card_wrapper}>
                    <Card className={`${displaycard.luminson__single_card} ${displaycard[theme]}`} >
                        <Card.Body>
                            <Card.Title className={displaycard.luminson__title}><h3>{title}</h3></Card.Title>
                            <div className={displaycard.luminson__icon}>
                                <Icon icon={image} />
                                <span className={displaycard.luminson__plus_icon}><FaPlus /></span>
                            </div>
                            {text}
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </>
    );
}

export default DisplayCard;