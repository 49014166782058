import homeefficientfirst from './HomeEfficientFirst.module.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import SectionHeading from '../sections/partials/SectionHeading';
import { TiTick } from "react-icons/ti";
import { Image } from 'react-bootstrap';
import HeroImage from '../sections/images/heroImage.webp';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import Card from 'react-bootstrap/Card'
import chevronDown from "../sections/images/chevron.svg";
import { Helmet } from 'react-helmet';

const HomeEfficientFirst = () => {


    const AccordionItem = ({ header, ...rest }) => (
        <Item
          {...rest}
          header={
            <>
              {header}
              <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
            </>
          }
        />
      );


    const items = [
        {
            header: 'What are the benefits of the Luminson Complete™ Solution?',
            content: 'With Luminson Complete™ we are able to suggest affordable energy solutions that would help significantly. By first Conducting an energy efficiency analysis we can pinpoint areas where your home is losing energy and enable you to eliminate that loss quickly, leading to significant savings. With an energy-efficient home, you may need 2-6 fewer solar panels, translating to thousands of dollars in savings on your total system price.'
        },
        {
            header: 'How does smart home energy monitoring work with my solar system?',
            content: 'By utilizing smart home energy monitoring, you can stay up-to-date on all the activities taking place within your home and solar system. With this technology, you can track the generation and consumption of energy in real-time, for a more affordable price than traditional solar monitoring systems.'
        },
        {
            header: 'What are the benefits of The energy efficiency  analysis?',
            content: 'An energy efficiency analysis can identify areas in your home where energy is being wasted and help you to eliminate that loss, resulting in significant savings immediately. A more energy-efficient home requires fewer solar panels, typically 2-6 panels less, which can translate to thousands of dollars in savings on your overall solar system cost.'
        }
    ];



    return (
        <>
         <Helmet>
        <title>Home Efficient</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <div className={`section ${homeefficientfirst.luminson__homeefficient_hero_section}`}>
                <Image src={HeroImage} />
                <Container className='h-100'>
                    <Row md={12} className='h-100'>
                        <Col md={12} className='d-flex align-items-center'>
                            <div className={homeefficientfirst.luminson__homeefficientfirst_caption}>
                                <h4>A Different Approach.</h4>
                                <h1>Home Efficiency First</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={`section ${homeefficientfirst.luminson__homeefficient_our_approach}`}>
                <Container>
                    <Row>
                        <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_text}>
                            <p>At Luminson Energy, we adopt a different approach to Solar that first begins with an energy efficiency analysis of your entire home utilizing our patented technology. By first improving the efficiency of your home, we can help you reduce your energy consumption and save money even before you start producing new renewable energy with solar. </p>
                            <p>To ensure that your investment is well-protected, we offer our patented Smart energy monitoring technology, which provides real-time insights into how your energy is being used.</p>
                            <p>The outcome of this comprehensive approach is a smarter, cleaner, and Energy Star-certified home that is fully equipped for the future.</p>
                        </span>
                    </Row>
                    <Row>
                        <Col lg={4} md={4} sm={4} className={`px-0 ${homeefficientfirst.luminson__homeefficient__our_approach_left_col}`}>
                            <div className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper}>
                                <span style={{borderBottom: 'none'}}  className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                <h6 style={{visibility: 'hidden'}}> Hide<br /> Me</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Clear Energy</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Smart Energy Monitoring</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Energy Star Certification</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Increased Home Value</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Feewer Solar Panels Required</h6>
                                </span>
                                <span className={homeefficientfirst.luminson__homeefficient__our_approach_left_wrapper_value}>
                                    <h6>Annual Energy Savings</h6>
                                </span>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={4} className='px-0'>
                            <div className={homeefficientfirst.luminson__homeefficient__our_approach_right}>
                                <Row>
                                    <Col lg={3} md={3} sm={3} className={`px-0 ${homeefficientfirst.luminson__homeefficient__our_approach_right_col} ${homeefficientfirst.luminson__homeefficient__our_approach_right_col_active}`}>
                                        <div className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper}>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6> Luminson<br /> Complete</h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>100-115%</h6>
                                            </span>

                                        </div>
                                    </Col>


                                    <Col lg={3} md={3} sm={3} className={`px-0 ${homeefficientfirst.luminson__homeefficient__our_approach_right_col}`}>
                                        <div className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper}>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>Solar<br /> Energy</h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>70-100%+</h6>
                                            </span>
                                        </div>
                                    </Col>


                                    <Col lg={3} md={3} sm={3} className={`px-0 ${homeefficientfirst.luminson__homeefficient__our_approach_right_col}`}>
                                        <div className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper}>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>Smart Home Energy<br /> Monitoring</h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>13%</h6>
                                            </span>
                                        </div>
                                    </Col>
                                    
                                    <Col lg={3} md={3} sm={3} className={`px-0 ${homeefficientfirst.luminson__homeefficient__our_approach_right_col}`}>
                                        <div className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper}>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>Energy Efficiency<br /> Analysis</h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6 style={{visibility: 'hidden'}}><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                            <h6><TiTick /></h6>
                                            </span>
                                            <span className={homeefficientfirst.luminson__homeefficient__our_approach_right_wrapper_value}>
                                                <h6>15-20%</h6>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={`section ${homeefficientfirst.luminson__homeefficient_faq}`}>
                <Container>
                <div className={homeefficientfirst.luminson_sectionheading}>
                        <SectionHeading heading="Frequently Asked Questions" sub="" align="text-center" />
                    </div>
                    <Row>
                        <Accordion transition transitionTimeout={200}>
                            {items.map(({ header, content }, i) => (
                                <AccordionItem header={header} key={i}>
                                    {content}
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomeEfficientFirst;