import locationaddress from './LocationAddress.module.css'
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import Geocode from "react-geocode";

const LocationAddress = ({ nextStep, handleFormData, values }) => {

  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const initialState = () => 1;
  const places = useState(initialState);

  const [latlive, setLiveLat] = useState(0);
  const [lnglive, setLiveLng] = useState(0);
  const [autocomplete, setAutocomplete] = useState('');

  navigator.geolocation.getCurrentPosition(function (position) {
    setLiveLat(position.coords.latitude);
    setLiveLng(position.coords.longitude);
  });

  Geocode.setApiKey("AIzaSyBsOkp1Zli2_QpVA4vvrWKiHB7-lBAiQXo");

  Geocode.fromLatLng(latlive, lnglive).then(
    (response) => {
      console.log('------');
      console.log(response.plus_code.compound_code);
      const string = response.plus_code.compound_code; 
      // response.results[response.results.length - 2].formatted_address
      console.log(string.split(','));
      const state = string.split(',');
      console.log(state[0].split(' '));
      const city = state[0].split(' ');
      const newcity = city.slice(1);
      const location = state[1];
      // const location = newcity.join(" ") + ',' + state[1]; e.g .Sirhind, Punjab
      console.log('0000000000');
      console.log(location);
      localStorage.removeItem('state');
      localStorage.setItem('state', location);
      
    },
    (error) => {
      console.error(error);
    }
  );

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    if (
      validator.isEmpty(values.address)
    ) {
      setError(true);
    } else {
      setAnimation(true);
      setTimeout(() => {
        nextStep();
      }, 3000)
    }
  };

  localStorage.removeItem('newLocation');

  const onLoad = (auto) => {
    console.log("autocomplete: ", autocomplete);
      setAutocomplete(auto);
    
  }


  const onPlaceChanged = () => {

     if (autocomplete) {
      console.log('-------------');
      console.log(autocomplete.getPlace());
      console.log('-------------');
      const place = autocomplete.getPlace();

      localStorage.removeItem('places');
      localStorage.setItem('places', JSON.stringify(place));

    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  const defaultBounds = {
    north: latlive + 0.8,
    south: latlive - 0.8,
    east: lnglive + 0.8,
    west: lnglive - 0.8,
  };

  console.log(defaultBounds);
  // const mapContainerStyle = {
  //   height: "400px",
  //   width: "800px"
  // }


  // const onPlaceChanged = (place) => {
  //   setSearchResult(place)
  //   console.log(searchResult)
  // }


  const options = {
    componentRestrictions: { country: "us" },

  };

  return (
    <>
      <div>
        {!animation ? (
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Form onSubmit={submitFormData}>
                <Form.Group className={`mb-3 ${locationaddress.luminson__address}`}>
                  <Form.Label><h2>Enter your details to estimate your potential savings and unlock special pricing.</h2></Form.Label>
                  <Autocomplete onPlaceChanged={onPlaceChanged}
                    onLoad={onLoad} options={options}>
                    {/* <input
            type="text"
            placeholder="Search for Tide Information"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`
            }}
          /> */}


                    <Form.Control
                      type="text"
                      className='form-control'
                      placeholder="Enter Location"
                      name="address"
                      onBlur={handleFormData("address")}
                      places={places}
                      defaultValue={[values.address]}
                    />
                  </Autocomplete>

                  {error ? (
                    <Form.Text style={{ color: "red" }}>
                      Incorrect address
                    </Form.Text>
                  ) : (
                    ""
                  )}
                  {/* <Form.Control
                  style={{ border: error ? "2px solid red" : "" }}
                  name="address"
                  defaultValue={values.address}
                  type="text"
                  placeholder="Address"
                  onChange={handleFormData("address")}
                />
                {error ? (
                  <Form.Text style={{ color: "red" }}>
                    This is a required field
                  </Form.Text>
                ) : (
                  ""
                )} */}
                </Form.Group>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "2rem" }}>
                  <Button className={`${locationaddress.luminson__continue_button}`} type="submit">
                    Next
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        ) : (
          <>
            <div className="loadingAnimation">
              <h2>Searching for your home</h2>
              <div className="wrapper">
                <div class="ball-loader">
                  <div class="ball-loader-ball ball1"></div>
                  <div class="ball-loader-ball ball2"></div>
                  <div class="ball-loader-ball ball3"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LocationAddress;