import React, { useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import truthform from './TruthForm.module.css'
import ReCAPTCHA from "react-google-recaptcha";

const TruthForm = ({ setShowModal, setFormSubmitted }) => {
  const formRef = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (email, name) => {
    const emailData = new URLSearchParams();
    emailData.append('to_email', email);
    emailData.append('subject', `Hello ${name}, Here's your Truth About Solar EBook!`);
    emailData.append('message', 'Please find attached EBook pdf with this email. Thanks!');
    emailData.append('from_email', 'gdsa006@gmail.com');

    axios
      .post('/php/sendEmail.php', emailData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        console.log('Email sent successfully');
        // Handle success response
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Handle error response
      });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const email = event.target.email.value;
  //   const name = event.target.firstname.value;
  //   sendEmail(email, name); // Call the sendEmail function to send the email
  //   document.getElementById('myHubSpotForm').submit(); // Submit the HubSpot form
  //   setFormSubmitted(true); // Set the formSubmitted state to true
  //   setShowModal(true);
  // };

  const handleRecaptchaChange = (value) => {
    console.log("reCAPTCHA Value:", value);
    setRecaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = event.target.email.value;
    const name = event.target.name.value;

    formData.delete('g-recaptcha-response');

    try {
      setIsLoading(true);

      const response = await axios.post('https://leads.luminson.com/api/truth-forms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        sendEmail(email, name); // Call the sendEmail function to send the email
        setFormSubmitted(true); // Set the formSubmitted state to true
        setShowModal(true);
        event.target.reset(); // Reset the form
      } else {
        // Handle error here
      }
    } catch (error) {
      // Handle error here
    } finally {
    setIsLoading(false); // Reset isLoading to false after the API request completes
  }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formFullName">
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" name="name" placeholder="Enter your full name" required />
      </Form.Group>
      <Form.Group className="mt-3" controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="email" placeholder="Enter your email address" required />
      </Form.Group>
      <Form.Group className="mt-3" controlId="formEmail">
        <ReCAPTCHA
          sitekey="6Lfs8VkoAAAAAFVMdk6mKxdYsoRS2mnu40tSH97z"
          onChange={handleRecaptchaChange}
        />
      </Form.Group>
      <Button className={`mt-4 btn-block ${truthform.button}`} type="submit" disabled={!recaptchaValue}>
      {isLoading ? 'Loading...' : 'Get Your Free PDF'}
      </Button>
    </Form>
  );
};

export default TruthForm;
