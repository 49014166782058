import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import heroBG from "../sections/images/referral-header.webp";
import { Image } from 'react-bootstrap';
import referrals from './Referrals.module.css';
import thanks from "../sections/images/iStock-1433753318-_1_.webp";
import ReferralForm from '../sections/partials/ReferralForm';
import one from "../sections/images/1.svg";
import two from "../sections/images/2.svg";
import three from "../sections/images/3.svg";
import { Helmet } from 'react-helmet';

const Referrals = () => {
    return (
        <>
        <Helmet>
        <title>Referrals</title>
        <meta name="description" content="Your page description" />
      </Helmet>
            <div className={`section ${referrals.luminson__hero_section}`}>
            <div className={`${referrals.overlay}`}></div>
                <Image src={heroBG} className={`${referrals.luminson__hero_section_background_image}`} />
                <Container className={`${referrals.luminson__hero_section_container}`}>
                    <Row md={12} className={`d-flex align-items-center ${referrals.luminson__hero_section_row}`}>
                        <Col md={6} className={`d-flex justify-content-center`}>
                            <div className={`${referrals.luminson__hero_section_wrapper}`} >
                                <div className={referrals.h1}>Share the <span>Love.</span></div>
                                <div className={referrals.h5}> Earn a $1000 Reward for Referring a Friend</div>
                                <div className={referrals.h6}>When you introduce Luminson to a friend, and they choose to generate their own power with Solar, they'll receive a free Smart Home Energy Management System (Valued at $1590 Installed). As a thank you, you'll receive either $1000 or a charitable donation in your name. Join us in empowering others and enjoy the rewards of spreading the word about Luminson. Thank you for your support and trust.</div>
                            </div>
                        </Col>
                        <Col md={6} className={`${referrals.form_container}`}>
                            <div className={`${referrals.wrapper}`}>
                                <ReferralForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={`section ${referrals.luminson__howitworks_section}`}>
                <Container className={`${referrals.luminson__howitworks_container}`}>
                    <div className={referrals.sectionHeading}>How it Works</div>
                    <Row md={12} className={`d-flex align-items-center ${referrals.luminson__howitworks_row}`}>
                        <Col md={4} className={`d-flex justify-content-center`}>
                            <div className={`${referrals.luminson__howitworks_wrapper}`} >
                                <div className={`d-flex align-items-center justify-content-center ${referrals.circle}`}>
                                <span className= {` ${referrals.circleNumber} `}><Image src={one} /></span>
                                </div>
                                <div className={referrals.h4}>
                                    <span>
                                    Fill out the Form.
                                    </span>
                                </div>
                                <p>
                                (Enter your referrals information)
                                </p>
                            </div>
                        </Col>

                        <Col md={4} className={`d-flex justify-content-center`}>
                            <div className={`${referrals.luminson__howitworks_wrapper}`} >
                                <div className={`d-flex align-items-center justify-content-center ${referrals.circle}`}>
                                <span className= {` ${referrals.circleNumber} `}><Image src={two} /></span>
                                </div>
                                <div className={referrals.h4}>
                                    <span>
                                    Check your Inbox.  (email icon)
                                    </span>
                                </div>
                                <p>
                                (We will give you weekly email status updates)
                                </p>
                            </div>
                        </Col>

                        <Col md={4} className={`d-flex justify-content-center`}>
                            <div className={`${referrals.luminson__howitworks_wrapper}`} >
                                <div className={`d-flex align-items-center justify-content-center ${referrals.circle}`}>
                                <span className= {` ${referrals.circleNumber} `}><Image src={three} /></span>
                                </div>
                                <div className={referrals.h4}>
                                    <span>
                                    Earn your $1000
                                    </span>
                                </div>
                                <p>
                                (Receive a check or decide)
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <p className={referrals.extraText}>*Payments are distributed when the solar installation is completed.</p>
                </Container>
            </div>

            <div className={`section ${referrals.luminson__widetext_section}`}>
                <Container className={`${referrals.luminson__widetext_container}`}>
                    <Row md={12} className={`d-flex align-items-center ${referrals.luminson__widetext_row}`}>
                        <Col md={12} className={`d-flex justify-content-center`}>
                            <div className={referrals.h4}>
                                Remember, you and your friends make the world a better place by going solar!
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={`section ${referrals.luminson__thankyou_section}`}>
                <Container className={`${referrals.luminson__thankyou_section_container}`}>
                    <Row md={12} className={`d-flex align-items-center ${referrals.luminson__thankyou_section_row}`}>
                        <Col md={6} className={`d-flex justify-content-center`}>
                            <Image src={thanks}  />
                        </Col>
                        <Col md={6} className={`${referrals.content}`}>
                            <div className={referrals.h4}>
                                Your Friends Will Thank You.
                            </div>
                            <p>
                                Since you've gone solar you know how rewarding this change can be. You're saving money, you're helping heal our planet, and you're living more comfortably.
                            </p>
                            <p>
                                But many people still think that solar is expensive and are too intimidated to start. That's why it would mean the world to us if you could tell your friends, family, coworkers, neighbors (basically anyone you know or like!) about your experience going solar.
                            </p>
                            <p>
                                This small pay-it-forward act will get you and your friend $500 when they decide to go solar.
                            </p>
                            <p>
                                Thank you for sharing!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Referrals;
