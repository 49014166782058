import featuring from './Featuring.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Efficient from "./images/forget-solar.jpg";
import PrimaryButton from '../elements/PrimaryButton';
import SectionHeading from './partials/SectionHeading';
import SolarIcon from '../sections/images/House-with-solar-icon.svg';

const Featuring = () => {
    return (
        <>
            <div className={`${featuring.luminson__featuring_section}`}>
                <Container>
                    <Row md={12}>
                        <Col md={2} lg={2} className={`d-flex align-items-center justify-content-center d-none d-lg-block d-xl-block ${featuring.luminson__left}`}>
                            <div className={featuring.luminson__left_col}>
                                <div className={featuring.luminson__icon}>
                                    <Image src={SolarIcon} />
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={10} className={`${featuring.luminson__right}`}>
                            <div className={featuring.luminson__right_col}>
                                <Row md={12}>
                                    <Col md={4} className=''>
                                        <div className={featuring.luminson__col}>
                                            <span className={featuring.luminson__large}>
                                                $58,546+
                                            </span>
                                            <span className={featuring.luminson__small}>
                                                Client’s Monthly Savings
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={4} className=''>
                                        <div className={featuring.luminson__col}>
                                            <span className={featuring.luminson__large}>
                                                4.9/5
                                            </span>
                                            <span className={featuring.luminson__small}>
                                                Star Rating by Our Past Clients
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={4} className={featuring.luminson__no_padding}>
                                        <div className={featuring.luminson__col}>
                                            <span className={featuring.luminson__large}>
                                                51 Years
                                            </span>
                                            <span className={featuring.luminson__small}>
                                                Combined Experience
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <h3>It keeps getting better...</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Featuring;