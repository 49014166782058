import homepage from './Homepage.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Hero from '../sections/Hero';
import MediaLogos from '../sections/MediaLogos';
import Leader from '../sections/Leader';
import LessExpensive from '../sections/LessExpensive';
import WeDifferent from '../sections/WeDifferent';
import Process from '../sections/Process';
import Testimonials from '../sections/Testimonials';
import ForgetSolar from '../sections/ForgetSolar';
import CertifiedAdvisors from '../sections/CertifiedAdvisors';
import ExpertAdvice from '../sections/ExpertAdvice';
import Featuring from '../sections/Featuring';
import { Helmet } from 'react-helmet';

const Homepage = () => {
    
    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="A Leading Solar Energy Provider" />
            </Helmet>
            <Hero />
            <MediaLogos />
            <Leader />
            <ForgetSolar />
            <CertifiedAdvisors />
            <LessExpensive />
            <WeDifferent />
            <Featuring />
            <Process />
            <ExpertAdvice />
            <Testimonials />
        </>
    );
}

export default Homepage;