import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import SectionHeading from '../sections/partials/SectionHeading';
import { Helmet } from 'react-helmet';
import optout from './OptOut.module.css'
import { Modal, Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

const OptOut = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
        opt_out_type: 'promotional_emails', // Default value
    });

    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRecaptchaChange = (value) => {
        console.log("reCAPTCHA Value:", value);
        setRecaptchaValue(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOptOut = async () => {
        try {
            setIsLoading(true);

            // Make a POST request to your Opt-Out API with the form data
            const response = await fetch('https://leads.luminson.com/api/opt-out', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Opt-Out successful, show the modal and clear the form fields
                setShowModal(true);
                // Clear the form fields by resetting the formData state
                setFormData({
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    address: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    opt_out_type: 'promotional_emails', // Default value
                });
            } else {
                setMessage('Opt-Out failed. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false); // Reset isLoading to false after the API request completes
          }
    };



    return (
        <>
            <Helmet>
                <title>OptOut</title>
                <meta name="description" content="Your page description" />
            </Helmet>
            <div className={optout.luminson__optout_container}> {/* Center the form */}
                <div className={optout.luminson__optout_form}> {/* Style the form */}
                    <div className={`section ${optout.luminson__optout_section}`}>
                        <Container>
                            <h2>Information Overload?</h2>
                            <p>
                                We want to stay in touch, but only in ways you find helpful. If you no longer want to receive marketing or promotional information from Luminson Energy you choose which communication methods you'd like to opt out of below, then enter your information. Remember, if you opt out, we won't be able to let you know about special discounts or offers on products and services that might benefit you. If the information you provide doesn't match the information in our system, you may still receive promotional information from us.
                            </p>
                            <div className="form-group">
                                <label>First Name:</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="form-control"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    className="form-control"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone:</label>
                                <input
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Address:</label>
                                <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>City:</label>
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>State:</label>
                                <input
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    value={formData.state}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Postal Code:</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    className="form-control"
                                    value={formData.postal_code}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Opt Out of Communication:</label>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="opt_out_type"
                                            value="promotional_emails"
                                            checked={formData.opt_out_type === 'promotional_emails'}
                                            onChange={handleChange}
                                        />
                                        Opt out of Promotional Emails
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="opt_out_type"
                                            value="emails_and_texts"
                                            checked={formData.opt_out_type === 'emails_and_texts'}
                                            onChange={handleChange}
                                        />
                                        Opt out of Emails and Texts
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="opt_out_type"
                                            value="phone_calls"
                                            checked={formData.opt_out_type === 'phone_calls'}
                                            onChange={handleChange}
                                        />
                                        Opt out of Phone Calls
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="opt_out_type"
                                            value="all_communication"
                                            checked={formData.opt_out_type === 'all_communication'}
                                            onChange={handleChange}
                                        />
                                        Opt out of All Communication
                                    </label>
                                </div>
                            </div>

                            <div className="form-group">
                                <div>
                                    <ReCAPTCHA
                                        sitekey="6Lfs8VkoAAAAAFVMdk6mKxdYsoRS2mnu40tSH97z"
                                        onChange={handleRecaptchaChange}
                                    />
                                </div>
                            </div>
                            <button
                                className={optout.luminson__optout_button}
                                onClick={handleOptOut}
                                disabled={!recaptchaValue}
                            >
                                
                                {isLoading ? 'Loading...' : 'Opt-Out'}

                            </button>
                            <p>{message}</p>
                        </Container>
                    </div>
                </div>
            </div>


            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your request has been processed successfully.
                </Modal.Body>
                <Modal.Footer>
                    <Button className={optout.luminson__optout_button} onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default OptOut;
