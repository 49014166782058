import singleblogpost from './SingleBlogPost.module.css'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import { parseDate } from "../../helpers/parseDate";
import { useParams } from "react-router-dom";
import PrimaryButton from '../elements/PrimaryButton';
import { Helmet } from 'react-helmet';
import Post from '../sections/partials/Post';
import TruthForm from '../sections/partials/TruthForm';
import { Modal, Button } from 'react-bootstrap';

const SingleBlogPost = () => {
    const [image, setImage] = useState();
    const [title, setTitle] = useState();
    const [date, setDate] = useState();
    const [desc, setDesc] = useState();
    const [recentPosts, setRecentPosts] = useState([]);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const pdfUrl = process.env.PUBLIC_URL + "/pdfs/The-Real-Raw-Truth-About-Solar.pdf";
    const parse = require('html-react-parser');
    const { slug } = useParams();

    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
      };
      
      const resetForm = () => {
        setFormSubmitted(false);
        // Additional logic to reset the form fields if needed
      };

    useEffect(() => {
        // Fetch single blog post
        axios
            .get(`https://public-api.wordpress.com/wp/v2/sites/luminson.wordpress.com/posts?slug=${slug}`)
            .then((result) => {
                setImage(result.data[0].jetpack_featured_media_url);
                setTitle(result.data[0].title.rendered);
                setDate(result.data[0].date);
                setDesc(result.data[0].content.rendered);

                // Fetch related posts based on tags
                const tags = result.data[0].tags;
                if (tags && tags.length > 0) {
                    const tagIds = tags.map(tag => tag.id).join(',');
                    axios
                        .get(`https://public-api.wordpress.com/wp/v2/sites/luminson.wordpress.com/posts?tags=${tagIds}&per_page=3`)
                        .then((relatedResult) => {
                            setRelatedPosts(relatedResult.data);
                        })
                        .catch(console.error);
                }
            })
            .catch(console.error);

        // Fetch recent posts (limit to 7 posts)
        axios
            .get('https://public-api.wordpress.com/wp/v2/sites/luminson.wordpress.com/posts?per_page=10')
            .then((result) => {
                setRecentPosts(result.data);
            })
            .catch(console.error);
    }, [slug]);


    return (
        <>
            <Helmet>
                <title>{title && parse(title)}</title>
                <meta name="description" content="Your page description" />
            </Helmet>
            <div className={`section ${singleblogpost.luminson__blog_section}`}>
                <Container>
                    <Row>
                        <Col lg={9}>
                    <div className={singleblogpost.luminson__post_card_title}>
                                <h2>{title && parse(title)}</h2>
                            </div>
                            <div className={singleblogpost.luminson__post_card_meta}>
                                Published: <span className='font-weight-bold'>{parseDate(date)}</span>
                            </div>
                            </Col>

                        <Col lg={{ span: 9, offset: 0 }} >
                            <div className={singleblogpost.luminson__post_card_image}>
                                <Image src={image} />
                            </div>
                            
                            
                            <div className={singleblogpost.luminson__post_card_desc}>
                                {desc && parse(desc)}
                            </div>
                            <div className={singleblogpost.luminson__post_card_button}>
                                <span>
                                <h1>Thinking about Solar?</h1>
                                <h2>We take a different approach...</h2>
                                </span>
                                <span>
                                <p>Is your home efficient yet? Click to know.</p>
                                <PrimaryButton theme="green" name={<>START MY ENERGY SAVINGS ANALYSIS</>} link='/start-analysis/' />
                                </span>
                            </div>
                            <div className={singleblogpost.luminson__author_block}>
    <Container>
        <Row>
            <Col lg={5} md={12} className={`d-flex align-items-center justify-content-center ${singleblogpost.luminson__author_columnDivider}`}>
                <div className={singleblogpost.luminson__author_image}>
                    {/* Author Image */}
                </div>
                <div className={singleblogpost.luminson__author_name}>
                    
Paige Bennett
                </div>
            </Col>
            <Col lg={7} md={12} className="d-flex align-items-center">
                <p className={singleblogpost.luminson__author_summary}>
                Based in Los Angeles, Paige is a writer who is passionate about sustainability. She earned her Bachelor’s degree in Journalism from Ohio University and holds a certificate in Women’s, Gender and Sexuality Studies. She also specialized in sustainable agriculture while pursuing her undergraduate degree.
                </p>
            </Col>
        </Row>
    </Container>
</div>

<hr className='mt-5' />


                            <div className={singleblogpost.luminson__related_posts}>
                                <h3>Read More</h3>
                                <Row>
                                    {recentPosts.map((post) => (
                                        <Post image={post.jetpack_featured_media_url} title={post.title.rendered} desc={post.excerpt.rendered} date={post.date} slug={post.slug} mode="related-posts" />
                                    ))}
                                </Row>

                            </div>
                            
                        </Col>
                        <Col lg={{ span: 3, offset: 0 }}>

                        <div className={singleblogpost.luminson__sidebar_book_form}>
                        <p>Download Your Free Copy of our short ebook. The Real Truth About Solar In 2023.</p>

                        <TruthForm setShowModal={setShowModal} setFormSubmitted={setFormSubmitted} />
</div>
                            <div className={singleblogpost.luminson__sidebar_recent}>
                                <h3>Recent Posts</h3>
                                <ul>
                                    {recentPosts.map((post) => (
                                        <li key={post.id}>
                                            <a href={`/blog/${post.slug}`}>{parse(post.title.rendered)}</a>
                                            <span>{parseDate(post.date)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {formSubmitted && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Email Sent!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please check your email for the Truth About Solar EBook or click
                        <a href={pdfUrl} target='_blank'> here</a> to download it right now.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            </div>
        </>
    );
}

export default SingleBlogPost;