import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import navigation from './Navigation.module.css'
import { FiPhoneCall } from "react-icons/fi";
import logo from "../../components/sections/images/logo.png";
import logoflip from "../../components/sections/images/logoflip.png";
import { Image } from 'react-bootstrap';
import PrimaryButton from './PrimaryButton';
import { Link as Li, useLocation } from 'react-router-dom';
import { HiOutlineDownload } from "react-icons/hi";
import { Link } from 'react-scroll';
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import the menu icon
import { Link as RouterLink } from 'react-router-dom'; // Rename the router Link

const Navigation = () => {
    const location = useLocation();
    const isPartnersRoute = location.pathname === '/partners';
    const isReferralRoute = location.pathname === '/referrals';
    const isTruthRoute = location.pathname === '/thetruth';
    const [sidebarVisible, setSidebarVisible] = useState(false); // State to control sidebar visibility
    const [scrolled, setScrolled] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Navbar expand="lg" sticky="top" className={`${isTruthRoute ? 'd-none' : ''} ${navigation.luminson__nav}`}>
                <Container fluid className={`${navigation.luminson__nav_container} `}>
                    <Navbar.Brand ><Li to='/' className='d-flex align-items-center justify-content-center'>
                    <Image
                                src={scrolled ? logoflip : logo}
                                className={`${navigation.luminson__logo} ${scrolled ? navigation.scrolled_logo : ''}`}
                                width={scrolled ? 300 : 175}
                                style={{ marginTop: scrolled ? '5px' : '0' }}
                            />                        
                            </Li>
                        </Navbar.Brand>
                    <Navbar.Toggle onClick={toggleSidebar} className={navigation.mobileToggle} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {/* <Nav className={`mx-auto ${navigation.luminson__nav_links}`}>
                            <Nav.Link>
                                <Link
                                    activeClass="active"
                                    to="WeAreDifferent"
                                    spy={true}
                                    smooth={true}
                                    offset={-72}
                                    duration={500}
                                >What Makes Us Different</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link
                                    activeClass="active"
                                    to="HowItWorks"
                                    spy={true}
                                    smooth={true}
                                    offset={-72}
                                    duration={500}
                                >How it Works
                                </Link>
                            </Nav.Link>
                        </Nav> */}
                        <Nav className={`ml-auto d-flex align-items-center justify-content-center ${navigation.luminson__links}  ${isReferralRoute ? navigation.noDivider : ''}`}>

                            {(!isPartnersRoute && !isReferralRoute) && (
                                <>
                                    <Nav.Link href="tel: 8444040505" className={navigation.luminson__phone_number}><FiPhoneCall /> (844) 404-0505</Nav.Link>
                                    <PrimaryButton name={<>Start Analysis</>} theme='green' icon='' link='/start-analysis/' />
                                    <button className={`${navigation.sidebarToggle}`} onClick={toggleSidebar}>
                                        <FaBars />
                                    </button>
                                </>
                            )}

                            {isPartnersRoute && (
                                <>
                                    <span className={navigation.luminson__tag_text}>
                                        Increase your value. Create more revenue.
                                    </span>                                    <Link to="schedule" smooth duration={500} offset={-72}>
                                        <PrimaryButton name={<>Schedule a Call Today</>} theme="yellow" icon="" link="#" />
                                    </Link>
                                </>
                            )}

                            {isReferralRoute && (
                                <>
                                    <Nav.Link href="#" className={`${navigation.luminson__phone_number}`}><FiPhoneCall /> (844) 404-0505</Nav.Link>

                                </>
                            )}

                            {/* <Nav.Link href="#" className={navigation.luminson__chat_online}><FiMessageCircle /> Chat</Nav.Link> */}
                            <span className={navigation.luminson__download_icon}><HiOutlineDownload /></span>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className={`${navigation.sidebar} ${sidebarVisible ? navigation.active : ''}`}>
                {/* Sidebar content */}
                <div className={`${navigation.sidebarHeader}`}>
                    {/* Sidebar header content */}
                    <button className={` ${navigation.sidebarToggle}`} onClick={toggleSidebar}>
                        <FaTimes />
                    </button>
                </div>
                <ul className={`${navigation.sidebarMenu}`}>
                    {/* Sidebar menu items */}
                    <li><RouterLink  to="/">Home</RouterLink ></li>
                    <li><RouterLink  to="/home-efficient-first">Our Mission</RouterLink ></li>
                    <li><RouterLink  to="/thetruth">How It Works</RouterLink ></li>
                    <li><RouterLink  to="/blog">Learn About Solar</RouterLink ></li>
                </ul>
            </div>

        </>
    );
}

export default Navigation;