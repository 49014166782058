import React, { useEffect } from 'react';

const TestForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h1>My Component</h1>
      <div className="my-form">
        <h2>Contact Us</h2>
        <form
          id="myHubSpotForm"
          method="POST"
          action="https://forms.hubspot.com/uploads/form/v2/39709184/4f28fbf1-4528-46db-a9ed-27b58bfb4f2e"
        >
          {/* Add your form fields here */}
          <input type="text" name="firstname" placeholder="First Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default TestForm;
