import React from 'react';
import { GoogleMap, Marker, useJsApiLoader  } from "@react-google-maps/api";

const GoogleMaps = (props) => {


   
      


    const containerStyle = {
        width: '100%',
        height: '400px'
    };




    const center = {
        lat: Number(props.latitude),
        lng: Number(props.longtitude)
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })



    const [map, setMap] = React.useState(null)



    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])




    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])



    const onMarkerDragEnd = (coord) => {
        console.log(coord.latLng.lat().toString());
        console.log(coord.latLng.lng().toString());
        const newObj = {
            lat: coord.latLng.lat().toString(),
            lng: coord.latLng.lng().toString()
        }
        localStorage.removeItem('newLocation');
        localStorage.setItem('newLocation', JSON.stringify(newObj));
      };
     

    return isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          mapTypeId={"satellite"}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          {props.isMarkerShown && <Marker 
          position={center} 
          draggable={true}
          onDragEnd={(e) => onMarkerDragEnd(e)}

          />}
          
        </GoogleMap>
    ) : <></>






  }
export default GoogleMaps;