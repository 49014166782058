import majorappliances from './MajorAppliances.module.css'
import { Form, Card, Button } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import validator from "validator";
import React, { useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Image from 'react-bootstrap/Image'


const MajorAppliances = ({ nextStep, handleFormData, prevStep, values }) => {
    const [value, setValue] = React.useState(25);

    const [error, setError] = useState(false);

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { icon: 'adobe-svgrepo-com.svg', name: 'Gas', value: '1' },
        { icon: 'adobe-svgrepo-com.svg', name: 'Electric', value: '2' }
    ];

    const submitFormData = (e) => {
        e.preventDefault();
        // checking if value of first name and last name is empty show error else take to step 2
        if (
            validator.isEmpty(values.appliance)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };
    const [gender, setGender] = useState("Male");


    const onChangeValue = (event) => {
        setGender(event.target.value);
        alert(event.target.value);
    }

    return (
        <>
            <div>
                <Card style={{ border: 0 }}>
                    <Card.Body>
                        <Form onSubmit={submitFormData} className={majorappliances.luminson__major_appliances}>
                            <Form.Group className={`mb-5 ${majorappliances.luminson__major_appliances}`}>
                                <Form.Label><h2>What powers your major appliances ?</h2></Form.Label>
                                <div className={majorappliances.luminson__option_buttons_wrapper} onChange={handleFormData("appliance")}>
                                    <label for="gas" className={majorappliances.luminson__option_button}>
                                        <Image src={('/images/gas.svg')} width='100' className={majorappliances.image} />
                                        <input type="radio" id="gas" value="gas" name="appliances" checked={values.appliance === 'gas'}/> Gas
                                    </label>
                                    <label for="electric" className={majorappliances.luminson__option_button}>
                                        <Image src={('/images/electric.svg')} width='100' className={majorappliances.image} />
                                        <input type="radio" id="electric" value="electric" name="appliances" checked={values.appliance === 'electric'} /> Electric
                                    </label>
                                </div>
                                {error ? (
                                    <Form.Text style={{ color: "red", textAlign: 'center' }}>
                                        Select one option.
                                    </Form.Text>
                                ) : (
                                    ""
                                )}
                            </Form.Group>

                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <Button className={`${majorappliances.luminson__continue_button}`} onClick={prevStep}>
                                    Previous
                                </Button>
                                <Button type="submit" className={`${majorappliances.luminson__continue_button}`}>
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default MajorAppliances;