import brandlogo from './BrandLogo.module.css'
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'


const BrandLogo = (props) => {

    const logo = props.logo;
    console.log(logo);
    return (
        <>
            <Col className='d-flex align-items-center justify-content-center'>
                <Image src={('/images/' + props.logo)} className={brandlogo.image} />
            </Col>
        </>
    );
}

export default BrandLogo;