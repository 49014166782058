import footer from './Footer.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RiFacebookCircleLine, RiInstagramLine, RiTwitterLine, RiYoutubeLine } from "react-icons/ri";
import lumisonenergylogo from "../sections/images/luminson_footer.png";
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className={`section ${footer.luminson__footer}`}>
                <Container>
                    <Row md={12}>
                        <Col md={3} className={footer.luminson__footer_col}>
                            <h5>
                                &nbsp;
                            </h5>
                            <ul className={footer.luminson__footer_links}>
                                <li>
                                    <a href='#' className={footer.luminson__sister_companies}>
                                        <Image src={lumisonenergylogo} />
                                    </a>
                                </li>
                                <li>
                                    <div className={footer.luminson__social_links}>
                                        <h5>
                                            Connect With Us
                                        </h5>
                                        <ul>
                                            <li>
                                                <a href='https://www.facebook.com/LuminsonEnergy/' target="_blank">
                                                    <RiFacebookCircleLine />
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.instagram.com/luminsonenergy/'  target="_blank">
                                                    <RiInstagramLine />
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://twitter.com/LuminsonEnergy'  target="_blank">
                                                    <RiTwitterLine />
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.youtube.com/@luminsonenergy'  target="_blank">
                                                    <RiYoutubeLine />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} className={footer.luminson__footer_col}>
                            <h5 className={footer.luminson__footer_title}>LUMINSON Energy</h5>
                            <ul className={footer.luminson__footer_links}>
                                <li>
                                    <Link to="/home-efficient-first">Home Efficient First</Link>
                                </li>
                                <li>
                                    <a href='/start-analysis/'>
                                        Start Your Free Energy Analysis
                                    </a>
                                </li>
                                <li>
                                    <Link to="/privacy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} className={footer.luminson__footer_col}>
                            <h5 className={footer.luminson__footer_title}>We're Hiring</h5>
                            <ul className={footer.luminson__footer_links}>
                                <li>
                                    <a href='//luminsoncareers.com/'>
                                        Careers
                                    </a>
                                </li>
                                <li>
                                    <a href='//luminsoncareers.com/about-us/'>
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href='//luminsoncareers.com/how-we-hire'>
                                        How We Hire
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} className={footer.luminson__footer_col}>
                            <h5 className={footer.luminson__footer_title}>Support</h5>
                            <ul className={footer.luminson__footer_links}>
                                <li>
                                    <a href='tel:8444040505'>
                                        Talk With Us
                                    </a>
                                </li>
                                
                                <li>
                                    <Link to="/terms">Terms of Use</Link>
                                </li>
                                <li>
                                <Link to="/opt-out">Opt Out</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row md={12}>
                        <div className={footer.luminson__copyright}>
                            <p>
                                &#169; LUMINSON Inc. ALL RIGHTS RESERVED. | <a href="/sitemap.xml">Sitemap</a>
                            </p>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Footer;