import twocolsection from './TwoColSection.module.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import PrimaryButton from '../../elements/PrimaryButton';
import SectionHeading from './SectionHeading';


const TwoColSection = (props) => {

    return (
        <>

            <Row md={12}>

                <Col md={6} className={props.flip ? "order-1 order-md-12" : ""}>

                    <div className={twocolsection.luminson__twocolsection_image}>
                        <Image src={('/images/' + props.image + '.webp')} />
                    </div>

                </Col>

                <Col md={6} className={`d-flex align-items-center justify-content-center ${props.flip ? 'order-12 order-md-1' : ''} ${props.flip ? twocolsection.luminson__twocolsection_flip : ''} ${twocolsection.luminson__twocolsection_caption}`}>
                    <div className={twocolsection.luminson__twocolsection_wrapper}>
                        <div className={twocolsection.luminson__twocolsection_heading}>
                            <SectionHeading heading={props.heading} sub="" />
                        </div>
                        {props.text}
                        {props.buttonText && <PrimaryButton name={props.buttonText} theme={props.buttonColor} link='/home-efficient-first' />}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default TwoColSection;