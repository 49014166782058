import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import referralform from './ReferralForm.module.css';

const ReferralForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        lead: {
            currency: "USD",
            stage: "Qualified",
            source: "Reference",
            assignee: "jag@luminson.com",
            deal_size: '',
            conversion_probablity: 70,
            is_public: true,
            last_contacted_at: "2021-04-06 12:34:23",
            primary_contact: {
                name: "",
                email: "",
                title: "",
                phone_number: ""
            },
            company: {
                name: "",
                website: "",
                billing_address: {
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: ""
                }
            },
            custom_fields: [
                {
                    id: 380,
                    value: ""
                },{
                    id: 381,
                    value: ""
                },{
                    id: 382,
                    value: ""
                }
            ]
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "custom_fields_380") {
          // Update the referral name field
          setFormData((prevData) => ({
            ...prevData,
            lead: {
              ...prevData.lead,
              custom_fields: prevData.lead.custom_fields.map((field) => {
                if (field.id === 380) {
                  return {
                    ...field,
                    value: value
                  };
                }
                return field;
              })
            }
          }));
        }else if (name === "custom_fields_381") {
            // Update the referral phone field
            setFormData((prevData) => ({
              ...prevData,
              lead: {
                ...prevData.lead,
                custom_fields: prevData.lead.custom_fields.map((field) => {
                  if (field.id === 381) {
                    return {
                      ...field,
                      value: value
                    };
                  }
                  return field;
                })
              }
            }));
          } else if (name === "custom_fields_382") {
            // Update the referral email field
            setFormData((prevData) => ({
              ...prevData,
              lead: {
                ...prevData.lead,
                custom_fields: prevData.lead.custom_fields.map((field) => {
                  if (field.id === 382) {
                    return {
                      ...field,
                      value: value
                    };
                  }
                  return field;
                })
              }
            }));
          } else {
          // Update other fields
          setFormData((prevData) => ({
            ...prevData,
            lead: {
              ...prevData.lead,
              primary_contact: {
                ...prevData.lead.primary_contact,
                [name]: value
              }
            }
          }));
        }
      };
      
      

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTc5MzIyNGU2NzUyNWExNjBiOTQ1NTE1MGRhYmM4MjQxNjc0YmY3NjYzYmQ3N2UxYmM0NTIxYTAyMzQ0MWVjNzAxYzZjOGRiYTFlOGVmOGYiLCJpYXQiOjE2ODU5ODEzNDQsIm5iZiI6MTY4NTk4MTM0NCwiZXhwIjoxNzAxNzkyNTQzLCJzdWIiOiIzNTgiLCJzY29wZXMiOltdfQ.tMvESSUn67U3KdaMIZ4Sbl3ERcjlLGaphlNYUk1o67dLc88dPqnPXvluKl1n2WB5gd7JUoKJACRw99sqg51k1Hb2FnTswixoIAbMoFnTGVy3DZeZUpsqCfTzmYYZ2KPPJFt5olGNmBG7910CGu_6ePrd15qMfZdismz--Q94u3HbZbLeWYHqQEnk4_OzHIDXgYfUVsjPKabQWE4tMOKhwX0J0tpECUvMNTM70_xU5DidsOsKwroFQdXvbkfPFbKoiebzBKkkjE9MegnDu35dgycSALgOj27WQnt4nuYQInjRr3qU-CmkWhDYZLU_sBEtp7EhuZrZQwv0Gl59liSnL0kFcEitm1lBWlRviEdXJinh0CEb_7oIGJSQ7P15-f0VeNp5TQfR2AbK0LnPKuFaF-k_f9WnnkbbwyVsuIS7UXMo89YZsjYVwsIhus8qEGjZnPuKSBl7e24GenFPDHfu_bCN-E8O1IIiTeCEL5iJLR5h9jIXTPY1V7ScQe8V_QvYjUKxCoy2rmI8Jyr96W5a8uUu4I1kvfHc4Z2s77eCoA4cY6wZodRYXz6jofW65hYPhtH88wTIqBsax3V4dlv8aU4RYFadtAXyJRQaYNbuONyvQKjI4ShLVXxYDUlZuL7LLnPR4mWydM33HBic7TjlUhO4lb0QWws-o5SVzniYRsw',
            'workspace': '424'
            // Add more headers as needed
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(formData),
        };

        fetch('https://api.agencyjoy.com/v1/leads', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('API response:', data);
                // Reset the form fields
                setFormData({
                    lead: {
                        currency: "USD",
                        stage: "Qualified",
                        source: "Reference",
                        assignee: "jag@luminson.com",
                        deal_size: 6500,
                        conversion_probablity: 70,
                        is_public: true,
                        last_contacted_at: "2021-04-06 12:34:23",
                        primary_contact: {
                            name: "",
                            email: "",
                            title: "",
                            phone_number: ""
                        },
                        company: {
                            name: "",
                            website: "",
                            billing_address: {
                                address: "",
                                city: "",
                                state: "",
                                country: "",
                                zipcode: ""
                            }
                        },
                        custom_fields: [
                            {
                                id: 380,
                                value: ""
                            },{
                                id: 381,
                                value: ""
                            },{
                                id: 382,
                                value: ""
                            }
                        ]
                    }
                });
                setIsSubmitted(true); // Set the success status to true
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <Form onSubmit={handleSubmit} className={referralform.form}>
            <Form.Group controlId="name" className='mb-4'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.lead.primary_contact.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                />
            </Form.Group>
            <Form.Group controlId="email" className='mb-4'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="text"
                    name="email"
                    value={formData.lead.primary_contact.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                />
            </Form.Group>
            <Form.Group controlId="referral_name" className='mb-4'>
                <Form.Label>Referral Name</Form.Label>
                <Form.Control
    type="text"
    name="custom_fields_380"
    value={formData.lead.custom_fields.find(field => field.id === 380)?.value || ''}
    onChange={handleChange}
    placeholder="Enter referral name"
    required
  />
            </Form.Group>
            <Form.Group controlId="referral_phone" className='mb-4'>
                <Form.Label>Referral Phone</Form.Label>
                <Form.Control
                    type="tel"
                    name="custom_fields_381"
                    value={formData.lead.custom_fields.find(field => field.id === 381)?.value || ''}
                    onChange={handleChange}
                    placeholder="Enter referral phone"
                    required
                />
            </Form.Group>
            <Form.Group controlId="referral_email" className='mb-4'>
                <Form.Label>Referral Email</Form.Label>
                <Form.Control
                    type="email"
                    name="custom_fields_382"
                    value={formData.lead.custom_fields.find(field => field.id === 382)?.value || ''}
                    onChange={handleChange}
                    placeholder="Enter referral email"
                    required
                />
            </Form.Group>
            <Button type="submit" className={`btn-block ${referralform.customBtn}`}>
                {isSubmitted ? "Message Sent" : "Submit"}
            </Button>
            
        </Form>
    );
};

export default ReferralForm;